import { useState, useEffect } from "react";
import propTypes from "prop-types";
import { List, arrayMove } from "react-movable";
import { GripVertical, Eye, Trash3, EyeSlash } from "react-bootstrap-icons";
import heatMapIcon from "../assests/Styles/assets/heatMapIcon1.svg";
import "../assests/Styles/newviewer.css";
import { formatFlightDate, customTabletDimensions } from "../services/Common";
import "../assests/Styles/common.css";
import assessvertical from "../assests/Styles/assets/assessvertical.svg";

export default function Assessmentdatecombinations({
  assessmentDateType,
  orderArray,
  setAssessmentDateType,
  setSelectedCombinationForHeatMap,
  selectedCombinationForHeatMap,
  selectedCombinationForEyeOpen,
  setSelectedCombinationForEyeOpen,
  showTreatment,
  showTime,
  showCompare,
  setTreatmentDataLoaded,
  setFightDataLoaded,
  setCompareData1Loaded,
  setCompareData2Loaded,
  setSelectedTrialInfoForEyeOpen,
  customMobilePortrait,
  }) {
  const [items, setItems] = useState(assessmentDateType);
  useEffect(() => {
    setItems(assessmentDateType);
  }, [assessmentDateType]);

  const handleDelIcon = (obj) => {
    const index = items.findIndex((item) => item?.type === obj.type && item?.date === obj.date);
    if (index !== -1) {
      if (index === 0) {
        if (showTreatment) {
          setTreatmentDataLoaded(true);
        }
        if (showTime) {
          setFightDataLoaded(true);
        }
      }
      if (index === 0 || index === 1) {
        if (showCompare) {
          setCompareData1Loaded(true);
          setCompareData2Loaded(true);
        }
      }
      const temp = [...items];
      temp.splice(index, 1);
      setItems(temp);
      setAssessmentDateType(temp);
      if (
        selectedCombinationForHeatMap?.date === obj.date &&
        selectedCombinationForHeatMap?.type === obj.type
      ) {
        setSelectedCombinationForHeatMap(undefined);
      }
      if (
        selectedCombinationForEyeOpen?.date === obj.date &&
        selectedCombinationForEyeOpen?.type === obj.type
      ) {
        setSelectedCombinationForEyeOpen(undefined);
      }
    }
  };

  return (
    <List
      values={items}
      lockVertically={true}
      onChange={({ oldIndex, newIndex }) => {
        if (newIndex === 0) {
          if (showTreatment) {
            setTreatmentDataLoaded(true);
          }
          if (showTime) {
            setFightDataLoaded(true);
          }
        }
        if (newIndex === 0 || newIndex === 1 || oldIndex === 0 || oldIndex === 1) {
          if (showCompare) {
            setCompareData1Loaded(true);
            setCompareData2Loaded(true);
          }
        }
        setAssessmentDateType(arrayMove(items, oldIndex, newIndex));
      }}
      renderList={({ children, props }) => <div {...props}>{children}</div>}
      renderItem={({ value: assessment, index, props, isDragged }) => (
        // eslint-disable-next-line react/prop-types
        <div
          {...props}
          style={{ ...props.style, opacity: "1", zIndex: isDragged ? "999" : "auto" }}>
          <div
            key={assessment.type}
            className={
              customMobilePortrait
                ? "d-flex mt-1  justify-content-between movable-list"
                : "d-flex mt-1 align-items-center  justify-content-between movable-list"
            }
            style={{ height: customMobilePortrait ? "auto" : "32px" }}>
            <span className={customMobilePortrait ? "d-flex align-items-center" : ""}>
              {customMobilePortrait ? (<img src={assessvertical} height={24} width={24} alt="" />):(
              <GripVertical height={22} color="#D9D9D9" />)}
            </span>
            <span
              className={
                customMobilePortrait
                  ? "orderarray pr-1 d-flex align-items-center"
                  : "orderarray pr-1 "
              }>
              {orderArray[index]}
            </span>
            <span data-testid="mobile-layout" className={customMobilePortrait ? "mobile-type-date" : "type-date"}>
              {customMobilePortrait ? (
                <>
                  <div style={{ fontWeight: 400 }}>
                    {" "}
                    {assessment.assessmentType === "manual"
                      ? formatFlightDate(assessment.date).split(" ")[0]
                      : formatFlightDate(assessment.date)}
                  </div>
                  <div style={{ fontWeight: 400 }}>
                    {assessment.type.split("v:")[0]}
                    &nbsp;
                  </div>
                </>
              ) : (
                <>
                  <span style={{ fontWeight: 400 }}>
                    {assessment.type.split("v:")[0]}
                    &nbsp;
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    {" "}
                    {assessment.assessmentType === "manual"
                      ? formatFlightDate(assessment.date).split(" ")[0]
                      : formatFlightDate(assessment.date)}
                  </span>
                </>
              )}
            </span>
            <button
              className={
                customMobilePortrait
                  ? "d-flex justify-content-center button-back-border align-items-center"
                  : "d-flex align-items-center justify-content-center button-back-border"
              }
              style={{
                height: "24px",
                width: "24px",
                margin: customMobilePortrait ? "-3px 16px 0px 10px" : customTabletDimensions() ? "0px 15px" :"0px 16px 0px 10px",
                backgroundColor:
                  selectedCombinationForHeatMap?.date === assessment.date &&
                    selectedCombinationForHeatMap?.type === assessment.type
                    ? "#FFE8BC"
                    : "transparent"
              }}>
              <img
                src={heatMapIcon}
                alt="heatmap"
                role="listitem"
                onClick={() =>
                  setSelectedCombinationForHeatMap(
                    selectedCombinationForHeatMap?.date === assessment.date &&
                      selectedCombinationForHeatMap?.type === assessment.type
                      ? undefined
                      : assessment
                  )
                }
                height={16}
              />
            </button>
            <span className="d-flex justify-content-center" style={{ margin: customTabletDimensions() ? "0px 15px 0px 0px" : "0px 16px 0px 0px", }}>
              {selectedCombinationForEyeOpen?.date === assessment.date &&
                selectedCombinationForEyeOpen?.type === assessment.type ? (
                <EyeSlash
                  data-testid="eye-icon"
                  fill="#0D1A40"
                  role="button"                  
                  onClick={() => {
                    setSelectedCombinationForEyeOpen(undefined);
                    setSelectedTrialInfoForEyeOpen();
                  }}
                />
              ) : (
                <Eye
                  data-testid="eye-icon"
                  fill="#0D1A40"
                  role="button"
                  onClick={() => {
                    setSelectedCombinationForEyeOpen(assessment);
                    setSelectedTrialInfoForEyeOpen();
                  }}
                />
              )}
            </span>


            <Trash3
               data-testid="trash-icon"
              style={{margin: "0px 14px 0px 0px"}}
              fill="#0D1A40"
              role="button"
              onClick={() => {
                handleDelIcon(assessment);
              }}
            />
          </div>
        </div>
      )}
    />
  );
}

Assessmentdatecombinations.propTypes = {
  assessmentDateType: propTypes.any,
  orderArray: propTypes.any,
  value: propTypes.any,
  setAssessmentDateType: propTypes.any,
  setSelectedCombinationForHeatMap: propTypes.any,
  selectedCombinationForHeatMap: propTypes.any,
  isHeatMapActive: propTypes.any,
  selectedCombinationForEyeOpen: propTypes.any,
  setSelectedCombinationForEyeOpen: propTypes.any,
  showTreatment: propTypes.any,
  showTime: propTypes.any,
  showCompare: propTypes.any,
  setTreatmentDataLoaded: propTypes.any,
  setFightDataLoaded: propTypes.any,
  setCompareData1Loaded: propTypes.any,
  setCompareData2Loaded: propTypes.any,
  style: propTypes.any,
  setSelectedTrialInfoForEyeOpen: propTypes.any,
  customMobilePortrait: propTypes.any,
};
