import { Dropdown, DropdownButton } from "react-bootstrap";
import { ChevronRight, InfoCircleFill, EyeSlash, Eye } from "react-bootstrap-icons";
import PropTypes from "prop-types";
import "../assests/Styles/newviewer.css";
import { formatFlightDate } from "../services/Common";
import mdiCalendarIcon from "../assests/Styles/assets/mdiCalendarIcon.svg";
import { propTypes } from "react-bootstrap/esm/Image";

const BaseOverlayFlight = ({
  baseOverlayFlightOpen,
  toggleBaseOverlayFlight,
  flightDropdown,
  flightDatesContainerRef,
  selectedFlightDate,
  flightDates,
  handleFlightOption,
  flightDateRef,
  isDisabled = false,
  trialType,
  rasterLayersList,
  overlayLayersList,
  overlayVectors,
  overlayChecklist,
  currentRaster,
  activeOverlays,
  boundingBoxes,
  selectedBoundingBoxes,
  handleRaster,
  activerVectorLayers,
  plotVectorChanged,
  selectedVectors,
  selectedTrialInfoForEyeOpen,
  setSelectedTrialInfoForEyeOpen,
  selectedCombinationForEyeOpen,
  setSelectedCombinationForEyeOpen,
  baseOverlayRef
}) => {
  return (
    <>
      <div ref={baseOverlayRef}>
        <Dropdown
          show={baseOverlayFlightOpen}
          toggleBaseOverlayFlight={toggleBaseOverlayFlight}
          className="base-flight-dropdown-show">
          <Dropdown.Menu
            className="flight-overlay-toggle"
            style={{
              width: trialType !== "manual" ? "319px" : "276px",
              height:
                overlayVectors !== null
                  ? "310px !important"
                  : trialType !== "manual"
                    ? "288px"
                    : "auto"
            }}>
            {trialType !== "manual" && (
              <DropdownButton
                drop="end"
                variant="Light"
                className="baseOF-dropdown"
                onClick={flightDropdown}
                ref={flightDateRef}
                disabled={isDisabled}
                title={
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ height: "49px" }}>
                    <div className="flight-menu-button">
                      <span>
                        <img
                          src={mdiCalendarIcon}
                          alt=""
                          height={22}
                          style={{ paddingLeft: "7px", color: "#0D1A40", width: "30px" }}
                        />
                      </span>
                      <span style={{ marginLeft: "20px", color: "#0D1A40", fontSize: "12px" }}>
                        Flight Date &nbsp;
                        {selectedFlightDate ? formatFlightDate(selectedFlightDate) : " "}
                      </span>
                      <span style={{ marginLeft: "45px" }}>
                        <ChevronRight color="#0D1A40" height={8} />
                      </span>
                    </div>
                  </div>
                }>
                <div className="scrollbar-assessment p-2" style={{ background: "white" }}>
                  <div
                    ref={flightDatesContainerRef}
                    className="baseOF-dropdown d-flex flex-column justify-content-between">
                    <div className="dd-flight-title">
                      <InfoCircleFill data-testid="info-circle-fill"  fill="#FFB71E" height={20} width={20} /> &nbsp;&nbsp;Select a
                      flight date to show image
                    </div>
                    <div style={{ paddingLeft: "20px" }}>
                      {flightDates &&
                        Object.keys(flightDates)?.map((option) => (
                          <div className="p-1" key={option}>
                            <div className="d-flex align-items-center">
                              <input
                                type="radio"
                                name="options"
                                checked={selectedFlightDate === option}
                                onChange={() => handleFlightOption(option)}
                                id={`radio-${option}`}
                                value={option}
                              />
                              <label
                                htmlFor={`radio-${option}`}
                                style={{
                                  fontSize: "12px",
                                  color: "#0D1A40",
                                  cursor: "pointer"
                                }}>
                                {formatFlightDate(option)}
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </DropdownButton>
            )}
            <div>
              {trialType !== "manual" && Object.values(rasterLayersList) !== null && (
                <div className="overlay-menu">
                  <p className="my-0 overlay-label">BASE</p>
                  <div className="d-flex align-items-center overlay-item ">
                    {Object.values(rasterLayersList).map((text) => (
                      <div className="d-flex pr-2 " key={text}>
                        <input
                          type="radio"
                          className="assessment-input align-items-center"
                          name="visible"
                          onChange={(e) => handleRaster(e.target.value)}
                          checked={currentRaster === text}
                          value={text}
                          id={`base-radio-${text}`}
                        />
                        <label
                          className="ml-1 base-overlay-input-label"
                          htmlFor={`base-radio-${text}`}
                          style={{
                            cursor: "pointer"
                          }}>
                          {text}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div>
              {trialType !== "manual" && overlayLayersList.length > 0 && (
                <div
                  className={overlayVectors !== null ? "overlay-menu-boundingbox" : "overlay-menu"}
                  style={{ height: overlayVectors !== null ? "94px !important" : "" }}>
                  <p className="my-0 overlay-label">OVERLAY</p>
                  <div
                    className={
                      overlayVectors !== null
                        ? "d-flex align-items-center overlay-item-withboundingbox"
                        : activerVectorLayers != null
                          ? "d-flex align-items-center overlay-item"
                          : "d-flex align-items-center overlay-item"
                    }>
                    {Object.values(overlayLayersList)?.map((text) => (
                      <div className="d-flex pr-2" key={text}>
                        <input
                          type="checkbox"
                          className="assessment-input"
                          name="overlay"
                          value={text}
                          onChange={overlayChecklist}
                          checked={activeOverlays.includes(text)}
                          id={`overlay-checkbox-${text}`}
                        />
                        <label
                          className="ml-1 input-label base-overlay-input-label"
                          htmlFor={`overlay-checkbox-${text}`}
                          style={{
                            cursor: "pointer"
                          }}>
                          {text}
                        </label>
                      </div>
                    ))}
                    {overlayVectors !== null && (
                      <div className="d-flex pr-2" key="chkoverlayVectors">
                        <input
                          type="checkbox"
                          className="assessment-input"
                          value="Bounding Box"
                          onChange={boundingBoxes}
                          checked={selectedBoundingBoxes}
                          id="bounding-checkbox"
                        />
                        <label
                          className="ml-1 input-label input-label-checkbox"
                          htmlFor="bounding-checkbox"
                          style={{
                            cursor: "pointer"
                          }}>
                          Bounding Box
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div>
              {activerVectorLayers.length > 0 && (
                <div
                  className={
                    trialType !== "manual" ? "mr-3 overlay-menu remove-border" : "mr-2 overlay-manual-menu remove-border"
                  }
                >
                  <p className="my-0 overlay-label">TRIAL INFO</p>
                  <div className="d-flex align-items-center overlay-item">
                    {Object.values(activerVectorLayers)?.map((text) => (
                      <div className="d-flex pr-2" key={text}>
                        <input
                          type="checkbox"
                          className="assessment-input"
                          name="overlay"
                          checked={selectedVectors?.includes(text)}
                          value={text}
                          onChange={(e) => {
                            plotVectorChanged(e)
                            if (!e.target.checked) {
                              if (selectedTrialInfoForEyeOpen === text) {
                                setSelectedTrialInfoForEyeOpen(undefined);
                                setSelectedCombinationForEyeOpen(undefined);
                              }
                            }
                          }}
                          id={`trial-checkbox-${text}`}
                        />
                        <label
                          className="ml-1 base-overlay-input-label"
                          htmlFor={`trial-checkbox-${text}`}
                          style={{
                            cursor: "pointer"
                          }}>
                          {text}
                        </label>
                        {selectedVectors?.includes(text) && (
                          <span className="ml-2">
                            {selectedTrialInfoForEyeOpen === text ||
                              selectedCombinationForEyeOpen === text ? (
                              <EyeSlash
                                fill="#0D1A40"
                                role="button"
                                onClick={() => {
                                  setSelectedTrialInfoForEyeOpen(undefined);
                                  setSelectedCombinationForEyeOpen(undefined);
                                }}
                              />
                            ) : (
                              <Eye
                                fill="#0D1A40"
                                role="button"
                                onClick={() => {
                                  setSelectedTrialInfoForEyeOpen(text);
                                  setSelectedCombinationForEyeOpen(text);
                                }}
                              />
                            )}
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};
BaseOverlayFlight.propTypes = {
  data: PropTypes.any,
  toggleBaseOverlayFlight: PropTypes.any,
  baseOverlayFlightOpen: PropTypes.any,
  flightDropdown: PropTypes.any,
  flightContainerVisible: PropTypes.any,
  flightDatesContainerRef: PropTypes.any,
  selectedFlightDate: PropTypes.any,
  flightDates: PropTypes.any,
  handleFlightOption: PropTypes.any,
  flightDateRef: PropTypes.any,
  isEditViewer: PropTypes.any,
  isDisabled: PropTypes.any,
  trialType: PropTypes.any,
  rasterLayersList: PropTypes.any,
  overlayLayersList: PropTypes.any,
  overlayVectors: PropTypes.any,
  overlayChecklist: PropTypes.any,
  currentRaster: PropTypes.any,
  activeOverlays: PropTypes.any,
  boundingBoxes: PropTypes.any,
  selectedBoundingBoxes: PropTypes.any,
  handleRaster: PropTypes.any,
  assessmentDrawerVisible: PropTypes.any,
  toggleAssessmentDrawerVisible: PropTypes.any,
  activerVectorLayers: PropTypes.any,
  plotVectorChanged: PropTypes.any,
  selectedVectors: PropTypes.any,
  selectedTrialInfoForEyeOpen: PropTypes.any,
  setSelectedTrialInfoForEyeOpen: PropTypes.any,
  selectedCombinationForEyeOpen: propTypes.any,
  setSelectedCombinationForEyeOpen: propTypes.any,
  baseOverlayRef: propTypes.any
};
export default BaseOverlayFlight;
