/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from "react";
import { ChevronDown, ChevronUp, XLg, InfoCircle, X } from "react-bootstrap-icons";
import "../assests/Styles/newviewer.css";
import addIcon from "../assests/Styles/assets/addIcon.svg";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
import ChatList from "./ChatList";
import { deletePerson, AddPeopleToChatList } from "../services/API";
import { Spinner, customTabletDimensions } from "../services/Common";
import { useMobileOrientation } from "react-device-detect";

export default function AddRemovePeople({
  addPeopleModalRef,
  trail,
  groupsList,
  tooltipRef,
  tooltipVisible,
  setTooltipVisible,
  onCloseTooltip,
  setDeleteUser,
  deleteUser,
  loading,
  setAddPeopleChatList,
  setDeleteChatList,
  customMobilePortrait,
  customMobileLandscape,
  setShowChatDiv,
  setShowNewDiv,
  addPeople,
  setAddPeople,
}) {
  const [openState, setOpenState] = useState({});
  const [hoveredItem, setHoveredItem] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const containerRef = useRef(null);
  const accessToken = sessionStorage.getItem("accessToken");
  const [deletePayload, setDeletePayload] = useState({});
  const currentUserEmail = sessionStorage.getItem("userName");
  const [chatHistory, setChatHistory] = useState(false);
  const [addPeoplePayload, setAddPeoplePayload] = useState({});
  const [modalJoinTeam, setModalJoinTeam] = useState(false);
  const [selectedPeopleUserId, setSelectedPeopleUserId] = useState("");
  const [selectedGroupNameWhileAdding, setSelectedGroupNameWhileAdding] = useState("");
  const [toggledAccordian, setToggledAccordian] = useState("");
  // const [modalTeamsLink, setModalTeamsLink] = useState("");
 
  useEffect(() => {
    if (deleteUser) {
      deletePerson(
        deletePayload,
        (res) => {
          setDeletePayload({});
          setOpenState({});
          setDeleteUser(false);
          setDeleteChatList(true);
        },
        (err) => {
          console.log("err in delet user", err);
        }
      );
    }
  }, [deleteUser]);

  useEffect(() => {
    if (groupsList.length > 0 && modalJoinTeam) {
      const newPaylaod = {
        user_email: currentUserEmail,
        chat_group_id: addPeoplePayload?.chat_group_id,
        user_id: selectedPeopleUserId,
        chat_history: chatHistory,
        access_token: accessToken
      };
      AddPeopleToChatList(
        newPaylaod,
        (res) => {
          setAddPeople(false);
          setModalJoinTeam(false);
          setAddPeopleChatList(true);
        },
        (err) => {
          console.log("user is already added", err);
        }
      );
    }
  }, [modalJoinTeam]);

  const toggleOpen = (id, item, e, level) => {
    e.stopPropagation();
    setToggledAccordian(id);
    setOpenState((prev) => {
      const newState = { ...prev };
      let isOpening = false;

      if (level === 0) {
        // Close all parent accordions
        Object.keys(newState).forEach((key) => {
          if (key !== id && newState[key] === true) {
            newState[key] = false;
          }
        });

        // Toggle the clicked accordion
        newState[id] = !prev[id];
        isOpening = newState[id];
      } else {
        newState[id] = !prev[id];
      }

      if (level === 0) {
        setShowTooltip((prevTooltip) => {
          const newTooltipState = { ...prevTooltip };
          Object.keys(newTooltipState).forEach((key) => {
            newTooltipState[key] = false;
          });

          newTooltipState[id] = isOpening && !isUserInParent(item);
          return newTooltipState;
        });
        setTooltipVisible(isOpening && !isUserInParent(item));
      } else {
        setTooltipVisible(false);
      }
      return newState;
    });
  };

  const countGrandchildren = (item) => {
    let count = 0;
    if (item.children) {
      item.children.forEach((child) => {
        if (child.children) {
          count += child.children.length;
        }
      });
    }
    return count;
  };

  const handleCloseaddPeople = () => {
    setAddPeople(false);
  };

  const handleDeleteClick = (e, item) => {
    e.stopPropagation();
    let payload = {
      user_email: currentUserEmail,
      chat_group_id: item?.chatGroupId,
      membership_id: item?.id,
      access_token: accessToken
    };
    setDeletePayload(payload);
    setShowDeletePopup(true);
  };

  const handleDeleteConfirm = () => {
    setDeleteUser(true);
    setShowDeletePopup(false);
  };

  const isUserInParent = (parent) => {
    if (!parent.children) return false;
    return parent.children.some((child) =>
      child?.children.some(
        (grandchild) => grandchild.name.toLowerCase() === currentUserEmail.toLowerCase()
      )
    );
  };

  const closeTooltip = (e) => {
    e.stopPropagation();
    onCloseTooltip();
  };

  const handleAddPeopleClick = (item) => {
    setSelectedGroupNameWhileAdding(item?.name);
    let x = [];
    item.children.map((child) =>
      child.children.map((grandchild) =>
        x.push({ userId: grandchild.id, chatGroupId: grandchild.chatGroupId })
      )
    );
    const addPersonPayload = {
      chat_group_id: x[0].chatGroupId
    };
    setAddPeoplePayload(addPersonPayload);
    // setModalTeamsLink(item?.teamsLink);
  };

  const addUserToGroup = () => {
    setModalJoinTeam(true);
    setShowNewDiv(true);
    setShowChatDiv(true);
  };
  const renderAccordion = (items, level = 0, isModal = false, parentId = null) => {
    return items.map((item) => {
      const grandChild = item.children ? countGrandchildren(item) : 0;
      const childCount = level === 0 ? grandChild : item.children;
      const grandchildCount = childCount ? item.children.length : 0;
      const userInParent =
        level === 0
          ? isUserInParent(item)
          : isUserInParent(groupsList.find((parent) => parent.name === parentId));

      const accordionContent = (
        <div
          className="d-flex justify-content-between items-center space-x-2 mb-2 ml-1"
          onMouseEnter={() => level === 2 && setHoveredItem(item.name)}
          onMouseLeave={() => setHoveredItem(null)}
          style={{
            backgroundColor: level === 2 && hoveredItem === item.name ? "#D9D9D9" : "transparent",
            borderRadius: "4px",
            position: "relative"
          }}>
          <span>
            <span
              className={`cursor-pointer accordion-text-heading ${level === 2 ? "not-bold-text" : "bold-text"
                }`}
              onClick={(e) => toggleOpen(item.name, item, e, level)}>
              {item.name}
              {/* {level === 0 && " - "} */}
            </span>
            {/* <span>
              {grandchildCount > 0 && level === 0 && (
                <span className="count-accordion">({grandchildCount})</span>
              )}
            </span> */}
            <span>
              {item.children && item.children.length > 0 && (
                <button
                  onClick={(e) => toggleOpen(item.name, item, e, level)}
                  className="ml-auto icon-no-border">
                  {openState[item.name] ? (
                    <ChevronUp className="icon-no-border" role="button" />
                  ) : (
                    <ChevronDown className="icon-no-border" />
                  )}
                </button>
              )}
            </span>
          </span>
          <span>
            <span>
              {level === 0 && !isModal && (
                <span className="modal-join-team">
                  <img
                    data-testid="add-people-button"
                    src={addIcon}
                    alt=""
                    height={18}
                    width={18}
                    onClick={() => {
                      userInParent && setAddPeople(true);
                      handleAddPeopleClick(item);
                    }}
                    style={{
                      cursor: userInParent ? "pointer" : "not-allowed",
                      opacity: userInParent ? 1 : 0.5
                    }}
                  />
                </span>
              )}
            </span>
            <span>
              {level === 0 && (
                <span
                  className={isModal ? "modal-accordion-text-teams" : "accordion-text-teams"}
                  onClick={() => userInParent && window.open(item?.teamsLink, "_blank")}
                  style={{
                    cursor: userInParent ? "pointer" : "not-allowed",
                    opacity: userInParent ? 1 : 0.5
                  }}>
                  Join Teams
                </span>
              )}
              {level === 2 && hoveredItem === item.name && userInParent && (
                <X
                  data-testid="delete-button"
                  role="button"
                  width={20}
                  height={20}
                  color="#0D1A40"
                  onClick={(e) => handleDeleteClick(e, item)}
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "50%",
                    transform: "translateY(-50%)"
                  }}
                />
              )}
            </span>
          </span>
        </div>
      );

      const tooltipContent = (
        <Tooltip id={`tooltip-${item.name}`} className="chat-tooltip">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              m: "100px"
            }}>
            <InfoCircle fill="#FFFFFF" width={24} height={24} />
            <span style={{ marginLeft: "10px", color: "#FFFFFF", flex: 1 }}>
              <div>You are not part of this chat group</div>
              <div>Please contact the listed members to add you</div>
            </span>
            <X
              fill="#FFFFFF"
              width={24}
              height={24}
              onClick={(e) => closeTooltip(e)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </Tooltip>
      );
      return (
        <div
          key={item.name}
          className={`ml-${level * 4}`}
          data-parent-accordion={level === 0 ? item.name : parentId}>
          {level === 0 && !userInParent && item.name === toggledAccordian ? (
            <OverlayTrigger
              placement="top"
              overlay={tooltipContent}
              show={tooltipVisible && showTooltip[item.name]}
              ref={tooltipRef}>
              {accordionContent}
            </OverlayTrigger>
          ) : (
            accordionContent
          )}
          {item.children && item.children.length > 0 && openState[item.name] && (
            <div className="pl-0">
              {renderAccordion(
                item.children,
                level + 1,
                isModal,
                level === 0 ? item.name : parentId
              )}
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <>
      {loading ? (
        <div data-testid="loading-spinner" className="modal-center-spinner-full-height">
          <Spinner animation="border" className="custom-spinner" />
        </div>
      ) : (
        <div
          ref={containerRef}
          className="p-3 bg-white rounded accordion-container"
          style={{
            maxHeight: customMobilePortrait ? "calc(100vh - 180px)" : customMobileLandscape ? "calc(100vh - 160px)" : "460px",
            overflowY: "auto",
            marginRight: "0px",
            position: "relative"
          }}>
          {groupsList.length > 0 && renderAccordion(groupsList)}
        </div>
      )}
      {addPeople && (
        <Modal
          show={addPeople}
          centered
          onHide={handleCloseaddPeople}
          className={customMobileLandscape ? "add-people-landscape" : customTabletDimensions() ? "add-people-modal-tab" : "add-people-modal"}>
          <div ref={addPeopleModalRef}>
            <Modal.Header className="add-people-modal-header" data-testid="add-people-modal-header">
              Add People
              <div style={{ position: "absolute", top: "8px", right: "8px" }}>
                <XLg
                  className="cursor-pointer"
                  height={16}
                  width={16}
                  onClick={handleCloseaddPeople}
                />
              </div>
            </Modal.Header>
            <Modal.Body>
              <div>
                {addPeople && (
                  <div
                    className="bg-white rounded"
                    style={{ height: !customMobileLandscape && "350px", maxHeight: customMobileLandscape && addPeople ? "200px" : "339px", overflowY: "auto" }}>
                    <ChatList
                      trail={trail}
                      // setModalJoinTeam={setModalJoinTeam}
                      addPeople={addPeople}
                      setSelectedPeopleUserId={setSelectedPeopleUserId}
                      selectedGroupNameWhileAdding={selectedGroupNameWhileAdding}
                      groupsList={groupsList}
                    />
                  </div>
                )}
              </div>
              <div
                style={{ borderTop: "1px solid #ededed", paddingTop: "7px", paddingLeft: "10px" }}>
                <span>
                  <input type="checkbox" onClick={() => setChatHistory(true)} />
                </span>
                <span
                  style={{
                    fontSize: "14px",
                    color: "#0D1A40",
                    fontWeight: 500,
                    paddingLeft: "8px"
                  }}>
                  Include chat history
                </span>
              </div>
              <div className="d-flex justify-content-center align-items-center mt-4">
                <span className="add-people-modal-cancel cursor-pointer" onClick={handleCloseaddPeople}>CANCEL</span>
                <span className="add-people-modal-add cursor-pointer" onClick={()=> addUserToGroup()}>ADD</span>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      )}
      {showDeletePopup && (
        <div className="delete-popup-overlay" onClick={() => setShowDeletePopup(false)}>
          <div className="delete-popup" onClick={(e) => e.stopPropagation()}>
            <div style={{ fontSize: "12px", fontWeight: "700", position: "relative", top: "35px" }}>
              <InfoCircle fill="#F2BB05" height={24} width={24} /> &nbsp;{" "}
              <span className="deep-cove-color">
                Are you sure you want to remove this person from this chat?
              </span>
            </div>
            <div className="delete-popup-divider"></div>
            <div>
              <button onClick={() => setShowDeletePopup(false)} className="cancel-button">
                CANCEL
              </button>
              <button onClick={handleDeleteConfirm} className="confirm-button">
                YES
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

AddRemovePeople.propTypes = {
  addPeopleModalRef: propTypes.any,
  trail: propTypes.any,
  groupsList: propTypes.any,
  loading: propTypes.any,
  tooltipRef: propTypes.any,
  tooltipVisible: propTypes.any,
  setTooltipVisible: propTypes.any,
  onCloseTooltip: propTypes.any,
  setDeleteUser: propTypes.any,
  deleteUser: propTypes.any,
  setAddPeopleChatList: propTypes.any,
  addPeopleChatList: propTypes.any,
  setDeleteChatList: propTypes.any,
  customMobilePortrait: propTypes.any,
  customMobileLandscape: propTypes.any,
  setShowChatDiv: propTypes.any,
  setShowNewDiv: propTypes.any,
  addPeople: propTypes.any,
  setAddPeople: propTypes.any,
};
