import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useRef, useEffect } from "react";
import propTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import NewViewerBarChart from "./NewViewerBarChart";
import NewViewerFlightChart from "./NewViewerFlightChart";
import NewViewerCompareChart from "./NewViewerCompareChart";

import "../assests/Styles/charts.css";
import "../assests/Styles/color.css";

const useDeboubce = (callback, delay) => {
  const [timeoutId, setTimeoutId] = useState(null);
  const debounceCallBack = (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const id = setTimeout(() => {
      callback(...args);
    }, delay);
    setTimeoutId(id);
  };

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);
  return debounceCallBack;
};

export default function DifferentCharts({
  modelBool,
  isMobile,
  chartsValue,
  compareChartsValue,
  showTreatment,
  showTime,
  barData,
  barData2,
  flightData,
  noAssesment,
  noAssesment2,
  noMean,
  noMean2,
  error,
  isMobileLandscape,
  isTablet,
  showCompare,
  handleChartRadio,
  handleCompareChartRadio,
  assessmentDateType,
  treatmentDataLoaded,
  flightDataLoaded,
  compareData1Loaded,
  compareData2Loaded,
  collectionDate1,
  collectionDate2,
  navbarButtons,
  barExpand,
  showCompareUrl,
  compareCorrelationUrl,
  highlightPlots,
  chartPointValue,
  setChartPointValue,
  subPlots,
  selectedSubplots,
  setSelectedSubplots,
  setChartButtonSelected,
  chartButtonSelected,
  setSeletedSubPlotOptions,
  type,
  setBarData,
  setBarData2,
  setFlightData
}) {
  const CompareHeight = "normal-different-chart-compare";

  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);
  const [zeroSubPlots, setZeroSubplots] = useState(false);
  const [subPlotsLoader, setSubplotsLoader] = useState(true);

  useEffect(() => {
    if (chartButtonSelected === true && subPlots.length > 0) {
      setSelectedSubplots(["All", ...subPlots]);
      setSeletedSubPlotOptions(["All", ...subPlots]);
      setChartButtonSelected(false);
    }
  }, [
    chartButtonSelected,
    subPlots,
  ]);

  useEffect(()=>{
    if (showCompare === true) {
      if (compareData1Loaded && compareData2Loaded) {
        setSubplotsLoader(false);
      }
    } 
    if(showTreatment === true){
      if(treatmentDataLoaded){
        setSubplotsLoader(false);
      }
    }
    if(showTime === true){
      if(flightDataLoaded){
        setSubplotsLoader(false);
    }
  }
  },[
    subPlotsLoader,
    compareData1Loaded,
    compareData2Loaded,
    showCompare,
    showTreatment,
    showTime,
    treatmentDataLoaded,
    flightDataLoaded
  ]);

  useEffect(() => {
    if (showCompare === true || showTime === true || showTreatment === true) {
      setIsOpen(false);
    }
  }, [showTime, showTreatment, showCompare]);

  useEffect(() => {
    if (selectedSubplots.length === 0 && !subPlotsLoader) {
      setBarData([]);
      setBarData2([]);
      setFlightData([]);
      setZeroSubplots(true);
    } else {
      setZeroSubplots(false);
    }
  }, [selectedSubplots, subPlotsLoader]);

  const debouncedSetSelectedSubplots = useDeboubce((newSelectedSubplots) => {
    setSeletedSubPlotOptions(newSelectedSubplots);
  }, 1500); // Adjust the debounce delay as needed

  const handleSubplotSelect = (subplot, event) => {
    event.stopPropagation();
    let newSelection;

    if (subplot === "All") {
      if (selectedSubplots.length === subPlots.length + 1) {
        newSelection = [];
      } else {
        newSelection = ["All", ...subPlots];
      }
    } else {
      if (selectedSubplots.includes(subplot)) {
        newSelection = selectedSubplots.filter((item) => item !== subplot && item !== "All");
      } else {
        newSelection = [...selectedSubplots.filter((item) => item !== "All"), subplot];
      }

      if (newSelection.length === subPlots.length && !newSelection.includes("All")) {
        newSelection = ["All", ...newSelection];
      }
    }

    // Update the state based on conditions
    if (newSelection.length === 0) {
      setSelectedSubplots([]);
    } else if (newSelection.includes("All")) {
      setSelectedSubplots(["All", ...subPlots]);
    } else {
      setSelectedSubplots(newSelection);
    }

    debouncedSetSelectedSubplots(newSelection);
  };

  const toggleDropdown = (open) => {
    // if (!isOpen) {
    //   setHasOpenedDropdown(true);
    // }

    setIsOpen(open);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderDropdown = () => (
    <div className="dropdown-wrapper" style={{ position: "relative !important" }}>
      <Dropdown onToggle={toggleDropdown} ref={dropdownRef}>
        <div>
          <Dropdown.Toggle
            variant="light"
            id="dropdown-subplots"
            style={{
              position: "relative",
              right: "15px",
              height: "46px"
            }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "130px",
                height: "40px",
                borderRadius: "18px",
                border: "1px solid rgb(237, 237, 237)"
              }}>
              <span
                style={{
                  position: "relative",
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                  marginLeft: "9px",
                  padding: "4px",
                  fontFamily: "Helvetica",
                  color: "#000000",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}>
                {type}
              </span>
              {selectedSubplots?.length > 0 && selectedSubplots?.length != subPlots?.length + 1 && (
                <span className="count-badge">
                  {selectedSubplots?.filter((item) => item !== "All")?.length}/{subPlots?.length}
                </span>
              )}
              <span
                style={{
                  marginBottom: "10px",
                  height: "16px"
                }}>
                {isOpen === true ? (
                  <ChevronUp
                    color="#0D1A40"
                    style={{
                      width: "30px",
                      height: "16px"
                    }}
                  />
                ) : (
                  <ChevronDown
                    color="#0D1A40"
                    style={{
                      width: "30px",
                      height: "16px"
                    }}
                  />
                )}
              </span>
            </div>
          </Dropdown.Toggle>
        </div>
        <div className="custom-dropdown-menu">
          <Dropdown.Menu
            col-xs-12
            className="dropdown-menu-show"
            onClick={(e) => e.stopPropagation()}>
            <Dropdown.Item
              key="All"
              style={{ padding: ".25rem 0.5rem" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}>
              <input
                type="checkbox"
                className="mr-4"
                checked={selectedSubplots.includes("All")}
                onChange={(e) => handleSubplotSelect("All", e)}
                onClick={(e) => e.stopPropagation()}
                // style={{ marginRight: "10px !important" }}
              />
              <span
                style={{
                  color: "#0D1A40",
                  fontSize: "12px",
                  fontFamily: "Helvetica",
                  marginLeft: "10px"
                }}>
                All
              </span>
            </Dropdown.Item>

            {subPlots
              .filter((item) => item != "All")
              .sort(function (a, b) {
                return a - b;
              })
              .map((subplot) => (
                <Dropdown.Item
                  key={subplot}
                  style={{ padding: ".25rem 0.5rem" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}>
                  <input
                    type="checkbox"
                    className="mr-4"
                    checked={selectedSubplots.includes(subplot)}
                    onChange={(e) => handleSubplotSelect(subplot, e)}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <span
                    style={{
                      color: "#0D1A40",
                      fontSize: "12px",
                      fontFamily: "Helvetica",
                      marginLeft: "10px"
                    }}>
                    {subplot}
                  </span>
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </div>
      </Dropdown>
    </div>
  );

  return (
    <>
      {showTreatment === true && (
        <div className="different-charts-treatment">
          <div className="different-charts-treatment-child d-flex justify-content-between align-items-center">
            <div className={isMobile ? "col-6" : "col-7"} style={{ margin: "15px" }}>
              <input
                type="radio"
                value="Bar"
                checked={chartsValue === "Bar"}
                onChange={handleChartRadio}
              />
              <span className="ml-2 mr-2 input-label deep-cove-color">Bar</span>
              <input
                type="radio"
                value="Box"
                checked={chartsValue === "Box"}
                onChange={handleChartRadio}
              />
              <span className="ml-2 input-label deep-cove-color">Box</span>
            </div>
            <div
              className="col-5 d-flex justify-content-end "
              style={{ marginTop: "15px", marginRight: "20px" }}>
              {subPlots?.length > 0 && renderDropdown()}
            </div>
          </div>

          <NewViewerBarChart
            isModel={modelBool}
            chartsValue={chartsValue}
            treatmentDataLoaded={treatmentDataLoaded}
            barData={barData}
            noAssesment={noAssesment}
            noMean={noMean}
            error={error}
            isMobileLandscape={isMobileLandscape}
            isTablet={isTablet}
            isMobile={isMobile}
            navbarButtons={navbarButtons}
            barExpand={barExpand}
            highlightPlots={highlightPlots}
            chartPointValue={chartPointValue}
            setChartPointValue={setChartPointValue}
            zeroSubPlots={zeroSubPlots}
            subPlotsLoader={subPlotsLoader}
          />
        </div>
      )}
      {(showTime === true) && (
        <div className="different-charts-treatment">
          <div className="different-charts-treatment-child d-flex justify-content-between align-items-center">
            <div className="col-7" style={{ margin: "15px" }}>
              <span></span>
            </div>
            <div
              className="col-5 d-flex justify-content-end "
              style={{ marginTop: "15px", marginRight: "20px" }}>
              {subPlots?.length > 0 && renderDropdown()}
            </div>
          </div>
          <NewViewerFlightChart
            flightData={flightData}
            noAssesment={noAssesment}
            flightDataLoaded={flightDataLoaded}
            error={error}
            isModel={modelBool}
            isMobileLandscape={isMobileLandscape}
            isTablet={isTablet}
            isMobile={isMobile}
            navbarButtons={navbarButtons}
            barExpand={barExpand}
            zeroSubPlots={zeroSubPlots}
            subPlotsLoader={subPlotsLoader}
          />
        </div>
      )}

      {(showCompare === true) && (
        <div className={`different-chart-compare ${CompareHeight}`}>
          {assessmentDateType[0]?.type === undefined ||
          assessmentDateType[1]?.type === undefined ? (
            <span className="flight-one-date-error">
              <strong>Please select two assessments!</strong>
            </span>
          ) : (
            <>
              <div className="different-charts-treatment-child d-flex justify-content-between align-items-center">
                <div className="col-7" style={{ margin: "15px" }}>
                  <input
                    type="radio"
                    value="Compare"
                    checked={compareChartsValue === "Compare"}
                    onChange={handleCompareChartRadio}
                  />
                  <span className="ml-2 mr-2 input-label deep-cove-color">Compare</span>
                  <input
                    type="radio"
                    value="Correlation"
                    checked={compareChartsValue === "Correlation"}
                    onChange={handleCompareChartRadio}
                  />
                  <span className="ml-2 input-label deep-cove-color">Correlation</span>
                </div>
                <div
                  className="col-5 d-flex justify-content-end "
                  style={{ marginTop: "15px", marginRight: "20px" }}>
                  {subPlots?.length > 0 && renderDropdown()}
                </div>
              </div>
              <NewViewerCompareChart
                navbarButtons={navbarButtons}
                isModel={modelBool}
                chartsValue={compareChartsValue}
                barData1={barData}
                barData2={barData2}
                noAssesment1={noAssesment}
                noAssesment2={noAssesment2}
                compareData1Loaded={compareData1Loaded}
                compareData2Loaded={compareData2Loaded}
                noMean={noMean}
                noMean2={noMean2}
                error={error}
                isMobileLandscape={isMobileLandscape}
                isTablet={isTablet}
                isMobile={isMobile}
                collectionDate1={collectionDate1}
                collectionDate2={collectionDate2}
                barExpand={barExpand}
                showCompareUrl={showCompareUrl}
                compareCorrelationUrl={compareCorrelationUrl}
                zeroSubPlots={zeroSubPlots}
                subPlotsLoader={subPlotsLoader}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
DifferentCharts.propTypes = {
  modelBool: propTypes.any,
  isMobile: propTypes.any,
  chartsValue: propTypes.any,
  compareChartsValue: propTypes.any,
  showTreatment: propTypes.any,
  showTime: propTypes.any,
  barData: propTypes.any,
  barData2: propTypes.any,
  flightData: propTypes.any,
  noAssesment: propTypes.any,
  noAssesment2: propTypes.any,
  noMean: propTypes.any,
  noMean2: propTypes.any,
  error: propTypes.any,
  isMobileLandscape: propTypes.any,
  isTablet: propTypes.any,
  showCompare: propTypes.any,
  handleChartRadio: propTypes.any,
  handleCompareChartRadio: propTypes.any,
  assessmentDateType: propTypes.any,
  treatmentDataLoaded: propTypes.any,
  flightDataLoaded: propTypes.any,
  compareData1Loaded: propTypes.any,
  compareData2Loaded: propTypes.any,
  collectionDate1: propTypes.any,
  collectionDate2: propTypes.any,
  navbarButtons: propTypes.any,
  barExpand: propTypes.any,
  showCompareUrl: propTypes.any,
  compareCorrelationUrl: propTypes.any,
  highlightPlots: propTypes.any,
  chartPointValue: propTypes.any,
  setChartPointValue: propTypes.any,
  subPlots: propTypes.any,
  selectedSubplots: propTypes.any,
  setSelectedSubplots: propTypes.any,
  setChartButtonSelected: propTypes.any,
  chartButtonSelected: propTypes.any,
  type: propTypes.any,
  setSeletedSubPlotOptions: propTypes.any,
  setBarData: propTypes.any,
  setBarData2: propTypes.any,
  setFlightData: propTypes.any
};
