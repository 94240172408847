import { useState, useEffect, useRef } from "react";
import {
  Modal,
  Form,
  FormControl,
  Row,
  Col,
  Accordion,
  Container,
  Card,
  InputGroup,
  Spinner,
  ModalDialog
} from "react-bootstrap";
import propTypes from "prop-types";
import "../assests/Styles/newviewer.css";
import { ChevronDown, ChevronUp, Search, X } from "react-bootstrap-icons";
import Draggable from "react-draggable";
import { Resizable } from "re-resizable";

function RatingDescription({
  isRatingDescription,
  handleRatingClose,
  ratingDescriptions,
  ratingDescriptionsLoaded,
  isMobileLandscape,
  isMobile,
  isTablet,
  isDesktop
}) {
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState({});
  const inputRef = useRef(null);
  const result1 = ratingDescriptions && Object.entries(ratingDescriptions?.drone_data?.rating_descriptions);
  const result2 = ratingDescriptions && Object.entries(ratingDescriptions?.manual_data);
  const [suggestions, setSuggestions] = useState(result1);
  const manualResult = result2?.map((item) => item[1]);
  const [manualSuggestions, setManualSuggestions] = useState(manualResult);
  const modalRef = useRef(null);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [resizablePosition, setResizablePosition] = useState({ width: 840, height: 540 });
  const [showIndex, setShowIndex] = useState("");

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchValue]);

  const handleToggle = (index, event) => {
    event.preventDefault();
    event.stopPropagation();
    setShow((prevOpenItem) => ({
      ...prevOpenItem,
      [index]: !prevOpenItem[index]
    }));



    setShowIndex(index);
    setTimeout(() => {
      const element = document.getElementById(`item_${index}`);
      element.scrollIntoView({ block: "nearest", inline: "start" });
    });
  };


  const handleDragStop = (e, data) => {
    setModalPosition({ x: data.x, y: data.y });
    setTimeout(() => {
      const showValues = Object.values(show).some((item) => item === true);
      const element = document.getElementById(`item_${showIndex}`);
      if (element && showValues === true) {
        element.scrollIntoView({ block: "nearest", inline: "start" });
      } else {
        window.scrollTo(0, 0);
      }
    });
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const filteredDroneSuggestions =
      ratingDescriptions &&
      Object.entries(ratingDescriptions?.drone_data?.rating_descriptions).filter(
        (item) => item[0].toLowerCase().indexOf(inputValue.toLowerCase()) != -1
      );
    setSuggestions(filteredDroneSuggestions);
    const filteredManualSuggestions =
      ratingDescriptions &&
      ratingDescriptions?.manual_data.filter(
        (item) => item.rating_name.toLowerCase().indexOf(inputValue.toLowerCase()) != -1
      );
    setManualSuggestions(filteredManualSuggestions);
    setSearchValue(inputValue);
  };

  const DraggableModalDialog = (props) => {
    return (
      <>
        {isDesktop ? (
          <Draggable
            handle=".RatingModalHeader"
            onClick={(e) => e.preventDefault()}
            // onStart={handleDragStart}
            onStop={handleDragStop}
            position={modalPosition}>
            <ModalDialog {...props} data-testid="modal-dialog" />
          </Draggable>
        ) : (
          <ModalDialog {...props} data-testid="modal-dialog" />
        )}
      </>
    );
  };

  const ratingDescriptionModalBody = () => {
    return (
      <div>
        <div>
          <Form>
            <div className="d-flex justify-content-center">
              <InputGroup
                className={
                  isDesktop ? "RatingModalSearchInputGroup" : "MobileRatingModalSearchInputGroup"
                }>
                <FormControl
                  ref={inputRef}
                  type="text"
                  placeholder="Search for Rating Description..."
                  className={isDesktop ? "RatingModalSearch" : "MobileRatingModalSearch"}
                  value={searchValue}
                  onChange={handleInputChange}
                  style={{ color: "#0D1A40" }}
                />
                <Search
                  className={
                    isMobile
                      ? "mobile-rating-search-icon"
                      : isMobileLandscape
                        ? "landscape-rating-search-icon"
                        : isTablet
                          ? "tablet-rating-search-icon"
                          : "rating-search-icon"
                  }
                  onClick={() => { }}
                  width={15}
                  height={15}
                  fill="#0D1A40"
                />
              </InputGroup>
            </div>
            <div>
              <div
                className={
                  isMobile || isMobileLandscape
                    ? "mobileRatingModalBody"
                    : isTablet
                      ? "tabletRatingModalBody"
                      : "desktopRatingModalBody"
                }
                style={{ maxHeight: isDesktop && `calc(${resizablePosition.height}px - 165px)` }}>
                {suggestions?.length > 0 &&
                  suggestions?.map((item, index) => (
                    <div key={index}>
                      <div className={"desktopRatingModalKeyContent"}> {item[0]}</div>
                      <div className={"desktopRatingModalValueContent"}>{item[1]}</div>
                    </div>
                  ))}

                {manualSuggestions?.length > 0 &&
                  manualSuggestions?.map((item, ItemIndex) => (
                    <div className="parent-div-accordion" key={ItemIndex}>
                      <Accordion
                        ref={modalRef}
                        onClick={(event) => handleToggle(ItemIndex, event)}
                        className={"accordion-button"}>
                        <Accordion.Item
                          eventKey={`item-${ItemIndex}`}
                          className={
                            show[ItemIndex] ? "rating-accordion-open" : "rating-accordion-closed"
                          }
                          id={`item_${ItemIndex}`}>
                          <Accordion.Header>
                            <div>
                              <span className="AccodionHeading">{item?.rating_name}</span>
                              <span className="icon-up-down">
                                {show[ItemIndex] ? (
                                  <ChevronUp
                                    class="collapse-icon"
                                    color="#859940"
                                    style={{ fontWeight: "bold" }}
                                  />
                                ) : (
                                  <ChevronDown
                                    class="collapse-icon"
                                    color="#859940"
                                    style={{ fontWeight: "bold" }}
                                  />
                                )}
                              </span>
                            </div>
                          </Accordion.Header>

                          <Accordion.Body>
                            <Container>
                              <Card>
                                <Card.Body className=" text-left deep-cove-color">
                                  {item?.assessed_by ||
                                    item?.rating_date.length > 0 ||
                                    item?.sample_size?.value ||
                                    item?.number_of_subsamples ? (
                                    <Row>
                                      {item?.assessed_by !== "" ? (
                                        <Col className="font-weight-bold" md={3} xs={4}>
                                          Assessed By
                                          <div className="font-weight-normal">
                                            {item?.assessed_by}
                                          </div>
                                        </Col>
                                      ) : null}

                                      {item?.rating_date?.length > 0 ? (
                                        <Col className="font-weight-bold" md={3} xs={4}>
                                          Rating date
                                          {item?.rating_date?.map((ratingItem, index) => (
                                            <div className="font-weight-normal" key={index}>
                                              {ratingItem}
                                            </div>
                                          ))}
                                        </Col>
                                      ) : null}
                                      {item?.sample_size !== "" ? (
                                        <Col className="font-weight-bold text-center" md={3} xs={4}>
                                          Sample Size
                                          <div className="font-weight-normal text-center">
                                            {item?.sample_size?.value}
                                          </div>
                                        </Col>
                                      ) : null}
                                      {item?.number_of_subsamples !== "" ? (
                                        <Col className="font-weight-bold text-center" md={3} xs={4}>
                                          Number of subsamples
                                          <div className="font-weight-normal text-center">
                                            {item?.number_of_subsamples}
                                          </div>
                                        </Col>
                                      ) : null}
                                    </Row>
                                  ) : null}

                                  {item?.se_description || item?.se_code ? (
                                    <Row className="border-top pt-4">
                                      {item?.se_code !== "" ? (
                                        <Col className="font-weight-bold" xs={3}>
                                          SE Code
                                          <div className="font-weight-normal">{item?.se_code}</div>
                                        </Col>
                                      ) : null}
                                      {item?.se_description !== "" ? (
                                        <Col className="font-weight-bold" xs={9}>
                                          SE Description
                                          <div className="font-weight-normal">
                                            {item?.se_description}
                                          </div>
                                        </Col>
                                      ) : null}
                                    </Row>
                                  ) : null}
                                  {item?.part_rated?.code ||
                                    item?.collection_basis ||
                                    item?.rating_unit?.minimum ||
                                    item?.rating_unit?.maximum ? (
                                    <Row className="border-top pt-4">
                                      {item?.part_rated?.code !== "" ? (
                                        <Col className="font-weight-bold" xs={6}>
                                          Part Rated
                                          <div className="font-weight-normal">
                                            {item?.part_rated?.code}
                                          </div>
                                          <br></br>
                                        </Col>
                                      ) : null}
                                      {item?.collection_basis !== "" ? (
                                        <Col className="font-weight-bold" xs={6}>
                                          Collection basis
                                          <div className="font-weight-normal">
                                            {item?.collection_basis?.unit}
                                            {item?.collection_basis?.value}
                                          </div>
                                          <br></br>
                                        </Col>
                                      ) : null}
                                      {item?.rating_unit !== "" ? (
                                        <Col className="font-weight-bold" xs={6}>
                                          Rating unit
                                          <div className="font-weight-normal">
                                            {item?.rating_unit?.unit}
                                          </div>
                                        </Col>
                                      ) : null}
                                      {item?.report_basis !== "" ? (
                                        <Col className="font-weight-bold" xs={6}>
                                          Reporting basis
                                          <div className="font-weight-normal">
                                            {item?.report_basis?.unit} {item?.report_basis?.value}
                                          </div>
                                          <br></br>
                                        </Col>
                                      ) : null}
                                      {item?.rating_type !== "" ? (
                                        <Col className="font-weight-bold" xs={6}>
                                          Rating Type
                                          <div className="font-weight-normal pb-4">
                                            {item?.rating_type}
                                          </div>
                                        </Col>
                                      ) : null}
                                    </Row>
                                  ) : null}
                                  {item?.crop_scientific_name ||
                                    item?.crop_variety ||
                                    item.pest_scientific_name ||
                                    item?.pest_stage?.majority ? (
                                    <Row className="border-top pt-4">
                                      {item?.crop_scientific_name !== "" ? (
                                        <Col className="font-weight-bold" xs={6}>
                                          Crop Scientific name
                                          <div className="font-weight-normal">
                                            {item?.crop_scientific_name}
                                          </div>
                                          <br></br>
                                        </Col>
                                      ) : null}
                                      {item?.crop_variety !== "" ? (
                                        <Col className="font-weight-bold" xs={6}>
                                          Crop variety
                                          <div className="font-weight-normal">
                                            {item?.crop_variety}
                                          </div>
                                        </Col>
                                      ) : null}
                                      {item?.crop_stage?.majority !== "" ? (
                                        <Col className="font-weight-bold" xs={6}>
                                          Crop stage
                                          <div className="font-weight-normal">
                                            {item?.crop_stage?.majority}
                                          </div>
                                        </Col>
                                      ) : null}
                                      {item?.pest_scientific_name !== "" ? (
                                        <Col className="font-weight-bold" xs={6}>
                                          Pest scientific name
                                          <div className="font-weight-normal">
                                            {item?.pest_scientific_name}
                                          </div>
                                          <br></br>
                                        </Col>
                                      ) : null}
                                      {item?.pest_stage?.majority !== "" ? (
                                        <Col className="font-weight-bold" xs={6}>
                                          Pest stage majority/min/max
                                          <div className="font-weight-normal">
                                            {`${item?.pest_stage?.majority}`}
                                          </div>
                                        </Col>
                                      ) : null}
                                    </Row>
                                  ) : null}
                                  {item?.equipment ? (
                                    <Row className="border-top pt-4">
                                      {item?.equipment !== "" ? (
                                        <Col className="font-weight-bold">
                                          Equipment
                                          <div className="font-weight-normal">
                                            {item?.equipment}
                                          </div>
                                        </Col>
                                      ) : null}
                                    </Row>
                                  ) : null}
                                </Card.Body>
                              </Card>
                            </Container>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  ))}
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  };

  return (
    <>
      {ratingDescriptionsLoaded ? (
        <div
          style={{ display: "flex", justifyContent: "center", position: "relative", top: "20%" }}>
          <Spinner data-testid="loading-spinner" style={{ width: "64px", height: "64px", color: "green" }} />
        </div>
      ) : (
        <>
          {isMobile || isMobileLandscape || isTablet ? (
            <div>
              <Modal
                show={isRatingDescription}
                onHide={handleRatingClose}
                className={
                  isMobile
                    ? "mobileDesktopRatingModal"
                    : isMobileLandscape
                      ? "mobile-landscape-RatingModal"
                      : isTablet
                        ? "tabletRating"
                        : " "
                }>
                <Modal.Header className={"RatingModalHeader"}>
                  Rating Description
                  <div
                    className={"rating-close-button"}
                    style={{ top: isMobile ? "2%" : "", right: isMobile ? "8%" : 0 }}>
                    <X data-testid="close-button" height={24} width={24} onClick={handleRatingClose} aria-label="close" />
                  </div>
                </Modal.Header>
                <Modal.Body className={"desktopRateModal"}>
                  {ratingDescriptionModalBody()}
                </Modal.Body>
              </Modal>
            </div>
          ) : (
            <div>
              <Modal
                // onClick={(e) => e.preventDefault()}
                show={isRatingDescription}
                onHide={handleRatingClose}
                dialogAs={DraggableModalDialog}
                className={
                  isMobile
                    ? "mobileDesktopRatingModal"
                    : isMobileLandscape
                      ? "mobile-landscape-RatingModal"
                      : isTablet
                        ? "tabletRating"
                        : " desktopRatingModal"
                }>
                <Resizable
                  data-testid="resizable-modal"
                  className="modal-resizable"
                  onResizeStop={(e, direction, ref, d) => {
                    const newWidth = resizablePosition.width + d.width;
                    const newHeight = resizablePosition.height + d.height;
                    setResizablePosition({ width: newWidth, height: newHeight });
                    setTimeout(() => {
                      const resizeShowValues = Object.values(show).some((item) => item === true);
                      const element = document.getElementById(`item_${showIndex}`);
                      if (element && resizeShowValues === true) {
                        element.scrollIntoView({ block: "nearest", inline: "start" });
                      } else {
                        window.scrollTo(0, 0);
                      }
                    });
                  }}
                  size={{ width: resizablePosition.width, height: resizablePosition.height }}>
                  <Modal.Header>
                    <div
                      className="RatingModalHeader col-11"
                      style={{ overflowWrap: resizablePosition.width <= 158 && "break-word" }}>
                      Rating Description
                    </div>

                    <div className="rating-close-button col-1" data-testid="close-button" onClick={handleRatingClose}>
                      <X height={24} width={24} aria-label="close" />
                    </div>
                  </Modal.Header>
                  <Modal.Body className={"desktopRateModal"}>
                    {ratingDescriptionModalBody()}
                  </Modal.Body>
                </Resizable>
              </Modal>
            </div>
          )}
        </>
      )}
    </>
  );
}
RatingDescription.propTypes = {
  ratings: propTypes.any,
  suggestions: propTypes.any,
  searchValue: propTypes.any,
  isRatingDescription: propTypes.bool,
  handleRatingClose: propTypes.any,
  ratingDescriptions: propTypes.any,
  ratingDescriptionsLoaded: propTypes.any,
  isMobileLandscape: propTypes.any,
  isMobile: propTypes.any,
  isTablet: propTypes.any,
  isDesktop: propTypes.any
};

export default RatingDescription;
