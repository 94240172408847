import { useState, useEffect, useRef } from "react";
import axios from "axios";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, CircularProgress, Stack } from "@mui/material";
import Home from "./Home";
import Navbar from "./Navbar";
import { loginSyt, collectionCountAPI, yearsAPI } from "../services/API";
import "open-iconic/font/css/open-iconic-bootstrap.scss";
import { HashLink as Link } from "react-router-hash-link";
import { useLocation } from "react-router-dom";

const drawerWidth = 100;
export default function Sidebar() {
  const [loading, setLoading] = useState(true);
  const [yearList, setYearList] = useState([]);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const idToken = sessionStorage.getItem("idToken");
  const location = useLocation();
  const [selectedYear, setSelectedYear] = useState();
  const effectRun = useRef(false);
  document.title = "Viewer | Loading...";

  function callAPI() {
    setLoading(true);
    if (
      sessionStorage.getItem("selectedYear") === null
        ? selectedYear
        : sessionStorage.getItem("selectedYear")
    ) {
      if (sessionStorage.getItem("selectedYear") !== selectedYear || selectedYear) {
        collectionCountAPI(
          selectedYear,
          (collection) => {
            setData(collection);
            sessionStorage.setItem("collectionData", JSON.stringify(collection));
            setLoading(false);
          },
          (err) => {
            console.log("error", err);
          }
        );
      }
    } else {
      setLoading(false);
    }
  }
  useEffect(() => {
    axios.defaults.withCredentials = true;
    if (effectRun.current === false) {
      loginSyt(
        idToken,
        (res) => {
          setError(null);
          if (res === 200) {
            if (sessionStorage.getItem("yearsList") === null) {
              yearsAPI((years) => {
                const yearList = years.sort(function (a, b) {
                  return b - a;
                });
                setYearList(yearList);
                sessionStorage.setItem("yearsList", JSON.stringify(yearList));
                if (yearList.length > 0) {
                  if (sessionStorage.getItem("selectedYear") === null) {
                    sessionStorage.setItem("selectedYear", yearList[0]);
                    setSelectedYear(yearList[0]);
                  } else {
                    setSelectedYear(sessionStorage.getItem("selectedYear"));
                  }
                }
                const yearSelected =
                  sessionStorage.getItem("selectedYear") === null
                    ? sessionStorage.setItem("selectedYear", yearList[0])
                    : sessionStorage.getItem("selectedYear");
                collectionCountAPI(
                  yearSelected,
                  (collection) => {
                    setData(collection);
                    setLoading(false);
                  },
                  (err) => {
                    console.log("error", err);
                  }
                );
              });
            } else {
              setYearList(JSON.parse(sessionStorage.getItem("yearsList")));
              setSelectedYear(sessionStorage.getItem("selectedYear"));
              setLoading(false);
            }
          }
        },
        (err) => {
          setError(err.message);
          window.location.reload();
          return () => {
            effectRun.current = false;
          };
        }
      );
      return () => {
        effectRun.current = true;
      };
    }
  }, [error, loading, selectedYear]);

  const siteUrl = `/home/sites/${selectedYear === undefined ? yearList && yearList[0] : selectedYear
    }`;
  const protocolUrl = `/home/protocol/${selectedYear === undefined ? yearList && yearList[0] : selectedYear
    }`;
  const trailUrl = `/home/trials/${selectedYear === undefined ? yearList && yearList[0] : selectedYear
    }`;

  const handleChange1 = (e) => {
    if (sessionStorage.getItem("selectedYear") !== e) {
      setLoading(true);
      sessionStorage.setItem("selectedYear", e);
      setSelectedYear(e);
      callAPI();
    } else {
      setLoading(false);
      setSelectedYear(sessionStorage.getItem("selectedYear"));
    }
  };
  return (
    <Box
      sx={{
        display: isMobile ? "block" : "flex",
        bgcolor: "#fafbfe",
        minHeight: isMobile ? "600px" : "auto",
        overflowY: "auto"
      }}>
      <CssBaseline />
      {isMobile ? (
        <Navbar
          data-testid="mobile-navbar"
          selectedYear={
            !loading && selectedYear != undefined ? selectedYear : yearList && yearList[0]
          }
          loading={loading}
        />
      ) : (
        <Drawer
          sx={{
            width: drawerWidth,
            bgcolor: "black",
            color: "hsla(0,0%,100%,.5)",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              bgcolor: "black",
              color: "hsla(0,0%,100%,.5)"
            }
          }}
          variant="permanent"
          anchor="left">
          {!loading ? (
            <div className="sidebar-social">
              <ul>
                <li>
                  <Link to={siteUrl}>
                    <i
                      className={
                        location.pathname.indexOf("sites") > -1
                          ? "oi oi-map active-icon"
                          : "oi oi-map"
                      }></i>
                    <span>Sites</span>
                  </Link>
                </li>
                <li>
                  <Link to={trailUrl}>
                    <i
                      className={
                        location.pathname.indexOf("trials") > -1
                          ? "oi oi-list active-icon"
                          : "oi oi-list"
                      }></i>
                    <span>Trials</span>
                  </Link>
                </li>

                <li>
                  <Link to={protocolUrl}>
                    <i
                      className={
                        location.pathname.indexOf("protocol") > -1
                          ? "oi oi-project active-icon"
                          : "oi oi-project"
                      }></i>
                    <span>Protocols</span>
                  </Link>
                </li>
              </ul>
            </div>
          ) : null}
        </Drawer>
      )}
      <Box
        component="main"
        sx={{
          bgcolor: "#fafbfe",
          padding: "8px 50px",
          width: "100%",
          paddingRight: "25px",
          paddingLeft: "30px",
          margin: isMobile ? "50px 0" : "",
          height: "100vh",
          overflowY: "scroll"
        }}>
        {loading ? (
          <Stack
            alignItems="center"
            sx={{ display: "flex", justifyContent: "center", height: "100vh" }}>
            <CircularProgress
              size={80}
              thickness={6}
              sx={{
                color: "green",
                "--CircularProgress-size": "80px",
                top: "50%",
                left: "50%"
              }}
            />
          </Stack>
        ) : (
          <Home collection={data} yearList={yearList} handleChange1={(e) => handleChange1(e)} data-testid="home-component" />
        )}
      </Box>
    </Box>
  );
}
