/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from "react";
import { Dropdown, DropdownButton, Button, Row, Col, Form, Modal } from "react-bootstrap";
import { ChevronDown, ChevronRight, EmojiFrown, InfoCircle, X } from "react-bootstrap-icons";
import "../assests/Styles/weather.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import newCross from "../assests/Styles/assets/newCross.svg";
import newCalendarIcon from "../assests/Styles/assets/newCalendarIcon.svg";
import propTypes from "prop-types";
import { customTabletDimensions } from "../services/Common";
import { getTrialDates, weatherChartDetails } from "../services/API";
import { isTablet } from "react-device-detect";
import { useMediaQuery } from "react-responsive";
import { envBackwardDay, envFormatDate, envForwarddDay, Spinner } from "../services/Common";
import Plot from "react-plotly.js";

const dateCategories = {
  drone_assessment_dates: "Drone assessments",
  manual_assessment_dates: "Manual assessments",
  planting_dates: "Planting date",
  application_dates: "Application date",
  maintenance_dates: "Maintenance date",
  growth_stage_dates: "Growth stage dates"
};

const WeatherChart = ({
  envExpand,
  selectedTrial,
  deviceId,
  deviceTrial,
  geometry,
  customMobileLandscape,
  customMobilePortrait,
  isMobileOnly,
  planDataLoaded,
  envTrack,
  selectedWeatherDate,
  setSelectedWeatherDate,
  selectedWeatherDevices,
  setSelectedWeatherDevices,
  selectedWeatherDateUrl,
  selectedWeatherDevicesUrl,
  weatherEndDate,
  setWeatherEndDate,
  weatherStartDate,
  setWeatherStartDate,
  weatherForwardDays,
  setWeatherForwardDays,
  setWeatherBackwardDays,
  weatherBackwardDays,
  weatherSelectedCategory,
  setWeatherSelectedCategory
}) => {
  const [deviceOpen, setDeviceOpen] = useState(false);
  const [openForwardDays, setOpenForwardDays] = useState(false);
  const [openBackwardDays, setOpenBackwardDays] = useState(false);

  const [selectedItems, setSelectedItems] = useState({});
  const [backwardDays, setBackwardDays] = useState(weatherBackwardDays || "10");
  const [forwardDays, setForwardDays] = useState(weatherForwardDays || "00");
  const [weatherCalendar, setWeatherCalendar] = useState(false);
  const [selectedByCalendar, setSelectedByCalendar] = useState(false);

  const [weatherSelectedOption, setWeatherSelectedOption] = useState(selectedWeatherDate || "");
  const [weatherSelectedDateCategory, setWeatherSelectedDateCategory] = useState(weatherSelectedCategory || "");
  const [trialDates, setTrialDates] = useState({});
  const weatherDropdownRef = useRef(null);
  const [weatherDropdownOpen, setWeatherDropdownOpen] = useState(false);
  const [deviceVariables, setDeviceVariables] = useState({});
  const today = new Date();
  let tenDaysBack = selectedWeatherDateUrl && weatherEndDate ? envBackwardDay(weatherEndDate, 10) : envBackwardDay(today, 10);
  let presentDay = selectedWeatherDateUrl && weatherEndDate ? envFormatDate(weatherEndDate, 1) : envFormatDate(today, 1);
  const [startDate, setStartDate] = useState(tenDaysBack);
  const [endDate, setEndDate] = useState(presentDay);
  const [minRange, setMinRange] = useState(tenDaysBack);
  const [maxRange, setMaxRange] = useState(presentDay);
  const [isApply, setIsApply] = useState(false);
  const [envData, setEnvData] = useState([]);
  const [envInterploteData, setEnvInterploteData] = useState([]);
  const [futureDate, setFutureDate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [responsive, setResponsive] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(false);
  const [stationId, setStationId] = useState("");
  const [stationType, setStationType] = useState("");
  const [stationGeometry, setStationGeometry] = useState([]);
  const [totalData, setTotalData] = useState();
  const [selectedOptionsOnApply, setSelectedOptionsOnApply] = useState(selectedWeatherDevices || []);
  const [deviceNameSelected, setDeviceNameSelected] = useState("");
  const weatherRef = useRef(null);
  const infoIconRef = useRef(null);
  const idToken = sessionStorage.getItem("idToken");
  const isLandScape = useMediaQuery({ orientation: "landscape" });

  const weatherDate = weatherSelectedOption.split("-");
  const weatherDate1 = weatherDate[0] + "-" + weatherDate[1] + "-" + weatherDate[2];

  const geo = { coordinates: [geometry.lng, geometry.lat], type: "Point" };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const deviceToggle = () => {
    setDeviceOpen(!deviceOpen);
  };
  const parseDates = (dateStr) => {
    const year = parseInt(dateStr.substring(0, 4), 10);
    const month = parseInt(dateStr.substring(4, 6), 10) - 1;
    const day = parseInt(dateStr.substring(6, 8), 10);
    const hour = parseInt(dateStr.substring(9, 11), 10);
    const mins = parseInt(dateStr.substring(12, 14), 10);
    const secs = parseInt(dateStr.substring(15, 17), 10);
    const hours = isNaN(hour) ? 0 : hour;
    const minutes = isNaN(mins) ? 0 : mins;
    const seconds = isNaN(secs) ? 0 : secs;
    return new Date(year, month, day, hours, minutes, seconds);
  };
  useEffect(() => {
    const selectedTrialHaveValue =
      selectedTrial != null && selectedTrial !== "All trials" && selectedTrial !== "No trials"
        ? selectedTrial
        : null;
    if (selectedTrial !== "All trials" && selectedTrial !== "No trials") {
      getTrialDates(
        selectedTrialHaveValue,
        (data) => {
          setTrialDates(data);
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  }, [selectedTrial]);

  // useEffect(() => {
  //   if (weatherSelectedOption !== "") {
  //     setFutureDate(new Date(envForwarddDay(weatherSelectedOption, 10).split("T")[0]) > new Date());
  //     setForwardDays("00");
  //   }
  // }, [weatherSelectedOption]);

  const fetchData = async () => {
    const url = `${process.env.REACT_APP_ART_URL}/weather/api/v1/weather`;
    const apiKey = `Bearer ${idToken}`;
    const requestDataWithDeviceID = JSON.stringify({
      endTime: endDate,
      startTime: startDate,
      stationId: deviceId,
      frequencies: ["hourly_avg", "hourly_sum"]
    });

    const requestDataWithOutDeviceID = JSON.stringify({
      endTime: endDate,
      startTime: startDate,
      location: geo,
      frequencies: ["hourly_avg", "hourly_sum"]
    });

    try {
      const response1 = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: apiKey,
          "Content-Type": "application/json"
        },
        body: deviceId != "" ? requestDataWithDeviceID : geometry ? requestDataWithOutDeviceID : ""
      });

      if (!response1.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response1.json();

      setError(false);
      let uniqueVariables = new Set();

      setTotalData(data[0]);

      const deviceData = data;
      if (deviceData?.length < 0 || data?.length < 0) {
        setError(true);
      } else {
        setEnvData(data);
        if (deviceData[0]?.geometry?.coordinates.length > 0) {
          if (deviceId != "" || deviceData[0]?.station !== null) {
            setStationGeometry(deviceData[0]?.geometry?.coordinates);
          } else setStationGeometry(deviceData[0]?.geometry?.coordinates[0]);
        }
        if (deviceData[0]?.station !== null) {
          setStationId(deviceData[0]?.station?.id);
          setStationType(deviceData[0]?.station?.type);
        } else {
          setStationId("");
          setStationType("");
        }
        data?.forEach((val) => {
          val?.datasets?.forEach((obj) => {
            obj?.measurements?.forEach((item) => {
              uniqueVariables.add(item?.variable?.name);
            });
          });
        });
      }
      setSelectedItems({});
      let variables = Array.from(uniqueVariables);
      let deviceNames =
        deviceTrial !== ""
          ? deviceTrial
          : deviceData[0]?.station !== null && deviceData[0]?.station?.id != ""
            ? deviceData[0]?.station?.id
            : "CE_HUB Interpolated Data";
      setDeviceNameSelected(deviceNames);
      let filterSelectedWeatherDevices = selectedWeatherDevices?.filter((item) => item !== "");
      if (
        (weatherSelectedOption === "" || selectedWeatherDateUrl !== null) &&
        variables.includes("TEMPERATURE") &&
        variables.includes("PRECIPITATION_TOTAL")
      ) {
        if (filterSelectedWeatherDevices?.length > 0) {
          selectedItems[deviceNames] = filterSelectedWeatherDevices;
          setSelectedItems(selectedItems);
        } else {
          selectedItems[deviceNames] = ["TEMPERATURE", "PRECIPITATION_TOTAL"];
          setSelectedItems(selectedItems);
        }
        // selectedWeatherDevices(selectedItems);
      } else if (
        (weatherSelectedOption === "" || selectedWeatherDateUrl !== null) &&
        variables.includes("TEMPERATURE") &&
        !variables.includes("PRECIPITATION_TOTAL")
      ) {
        if (filterSelectedWeatherDevices?.length > 0) {
          selectedItems[deviceNames] = filterSelectedWeatherDevices;
          setSelectedItems(selectedItems);
        } else {
          selectedItems[deviceNames] = ["TEMPERATURE"];
          setSelectedItems(selectedItems);
        }
      } else if (
        (weatherSelectedOption === "" || selectedWeatherDateUrl !== null) &&
        variables.includes("PRECIPITATION_TOTAL") &&
        !variables.includes("TEMPERATURE")
      ) {
        if (filterSelectedWeatherDevices?.length > 0) {
          selectedItems[deviceNames] = filterSelectedWeatherDevices;
          setSelectedItems(selectedItems);
        } else {
          selectedItems[deviceNames] = ["PRECIPITATION_TOTAL"];
          setSelectedItems(selectedItems);
        }
      } else if (isApply) {
        selectedItems[deviceNames] = [...new Set(selectedOptionsOnApply)];
        setSelectedItems(selectedItems);
      } else {
        selectedItems[deviceNames] = (filterSelectedWeatherDevices)?.split(",");
        setSelectedItems(selectedItems);
        selectedWeatherDevices(selectedItems);
      }

      if (weatherSelectedOption === "") {
        const date = new Date();
        setWeatherSelectedOption(date.toISOString().slice(0, 10));
      }
      deviceVariables[deviceNames] = variables.sort((a, b) => a?.localeCompare(b));
      setDeviceVariables(deviceVariables);
      setMinRange(startDate);
      setMaxRange(endDate);
      setIsLoading(false);
    } catch (error) {
      setError(true);
      setIsLoading(false);
      console.error("Error:", error);
    }
    callBackActovityTrackingApi();
  };

  const callBackActovityTrackingApi = () => {
    const payload = {
      deviceid:
        deviceTrial !== ""
          ? deviceTrial
          : stationId !== ""
            ? stationId
            : "CE_HUB Interpolated Data",
      variables: Object.values(selectedItems).flat(),
      startdate: startDate,
      enddate: startDate
    };
    envTrack(payload);
  };

  useEffect(() => {
    if ((weatherSelectedOption === "" && planDataLoaded === false)) {
      setIsLoading(true);
      fetchData();
    }
  }, [weatherSelectedOption, planDataLoaded]);

  useEffect(() => {
    if (selectedWeatherDate !== "" && selectedWeatherDateUrl && planDataLoaded === false) {
      setIsLoading(true);
      fetchData();
    }
  }, [selectedWeatherDate, planDataLoaded]);

  useEffect(() => {
    if (isApply) {
      setIsApply(false);
      setIsLoading(true);
      fetchData();
    }
  }, [endDate, startDate, deviceId, isApply]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        weatherDropdownRef.current &&
        !weatherDropdownRef.current.contains(event.target) &&
        !weatherRef.current.contains(event.target)
      ) {
        setWeatherCalendar(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutsideInfoIcon = (event) => {
      if (infoIconRef.current && !infoIconRef.current.contains(event.target)) {
        setOpenModal(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutsideInfoIcon);
    return () => {
      window.removeEventListener("mousedown", handleClickOutsideInfoIcon);
    };
  }, []);

  const formatShortWeekday = (locale, date) => {
    const weekday = date.getDay();
    return ["S", "M", "T", "W", "T", "F", "S"][weekday];
  };

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      if (date.getDay() === 0 || date.getDay() === 6) {
        return "weekends";
      } else {
        return "weekday";
      }
    }
  };

  const handleCheckboxChange = (key, elem) => {
    const item = `${elem}`;
    setSelectedItems((prev) => {
      const newSelectedItems = { ...prev };
      const currentCount = Object.values(newSelectedItems).flat().length;
      if (newSelectedItems[key]?.includes(item)) {
        newSelectedItems[key] = newSelectedItems[key].filter((i) => i !== item);
      } else {
        if (!newSelectedItems[key]) {
          newSelectedItems[key] = [];
        }
        if (newSelectedItems[key].length < 4 && currentCount < 4) {
          newSelectedItems[key].push(elem);
        }
      }
      setSelectedWeatherDevices(Object.values(newSelectedItems).flat());
      return newSelectedItems;
    });
  };

  const handleToggle = (isOpen) => {
    if (!isOpen) {
      setWeatherDropdownOpen(false);
    }
  };
  const handleRemoveChip = (key, item) => {
    setSelectedItems((prev) => {
      const newSelectedItems = { ...prev };
      newSelectedItems[key] = newSelectedItems[key].filter((i) => i !== item);
      return newSelectedItems;
    });
  };

  useEffect(() => {
    const totalSelected = Object.values(selectedItems).flat().length;
    if (totalSelected === 0) {
      // setWeatherSelectedOption("");
      // setWeatherSelectedDateCategory("");
      // // setBackwardDays("00");
      // setForwardDays("00");
    }
  }, [selectedItems]);

  const handleDeselectAll = (key) => {
    setSelectedItems((prev) => {
      const newSelectedItems = { ...prev };
      newSelectedItems[key] = [];
      return newSelectedItems;
    });
  };
  const handleClearAll = () => {
    setSelectedItems([]);
    //setWeatherSelectedOption("");
    setWeatherSelectedDateCategory("");
    setWeatherSelectedCategory("");
  };

  const emptyObjectCheck = (obj) => {
    for (const key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length > 0) {
        return false;
      }
    }
    return true;
  };

  const daysOptions = Array.from({ length: 11 }, (v, i) => i.toString().padStart(2, "0"));
  const availableForwardDays = daysOptions.slice(0, 11 - parseInt(backwardDays));
  const availableBackwardDays = daysOptions.slice(0, 11 - parseInt(forwardDays));

  const parseDateRange = (dateStr) => {
    if (dateStr) {
      let year = parseInt(dateStr?.substring(0, 4));
      let month = parseInt(dateStr?.substring(4, 6)).toString().padStart(2, "0");
      let day = parseInt(dateStr?.substring(6, 8)).toString().padStart(2, "0");
      let hour = parseInt(dateStr?.substring(9, 11)).toString().padStart(2, "0");
      let mins = parseInt(dateStr?.substring(12, 14)).toString().padStart(2, "0");
      let secs = parseInt(dateStr?.substring(15, 17)).toString().padStart(2, "0");
      let hours = isNaN(hour) ? "00" : parseInt(hour).toString().padStart(2, "0");
      let minutes = isNaN(mins) ? "00" : parseInt(mins).toString().padStart(2, "0");
      let seconds = isNaN(secs) ? "00" : parseInt(secs).toString().padStart(2, "0");

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }
  };

  useEffect(() => {
    if (weatherSelectedOption !== "" || selectedWeatherDateUrl !== null) {
      let plus = parseInt(forwardDays);
      let minus = parseInt(backwardDays);
      let selectedDate = selectedWeatherDateUrl !== null ? selectedWeatherDateUrl : weatherSelectedOption;
      const calculatedStartDate =
        plus > 0
          ? envForwarddDay(selectedDate, plus, 1)
          : envFormatDate(selectedDate, 1);
      const calculatedEndDate =
        minus > 0
          ? envBackwardDay(selectedDate, minus)
          : envFormatDate(selectedDate);

      if (plus > 0 && minus > 0) {
        setStartDate(envBackwardDay(selectedDate, minus));
        setEndDate(envForwarddDay(selectedDate, plus, 1));
        setWeatherStartDate(envBackwardDay(selectedDate, minus));
        setWeatherEndDate(envForwarddDay(selectedDate, plus, 1));
      } else if (minus > 0) {
        setStartDate(calculatedEndDate);
        setEndDate(envForwarddDay(selectedDate, plus, 1));
        setWeatherStartDate(calculatedEndDate);
        setWeatherEndDate(envForwarddDay(selectedDate, plus, 1));
      } else if (plus > 0) {
        setStartDate(envBackwardDay(selectedDate, minus));
        setEndDate(calculatedStartDate);
        setWeatherStartDate(envBackwardDay(selectedDate, minus));
        setWeatherEndDate(calculatedStartDate);
      }
    }
  }, [backwardDays, forwardDays, weatherSelectedOption, selectedWeatherDateUrl]);

  const backwardDaysToggle = () => {
    setOpenBackwardDays(!openBackwardDays);
  };

  const handleBackwardDays = (day) => {
    setWeatherBackwardDays(day);
    setBackwardDays(day);
  };

  const forwardDaysToggle = () => {
    setOpenForwardDays(!openForwardDays);
  };

  const handleForwarddDays = (days) => {
    setWeatherForwardDays(days);
    setForwardDays(days);
  };
  const handleCalender = (day) => {
    const date = day?.split("/");
    let month = parseInt(date[0]) < 10 ? "0" + date[0] : date[0];
    let days = parseInt(date[1]) < 10 ? "0" + date[1] : date[1];
    const dateString = date[2] + "-" + month + "-" + days;
    setWeatherSelectedOption(dateString);
    setSelectedByCalendar(true);
  };
  const totalSelected = Object.values(selectedItems).flat().length;

  const selectDateHidden = !!(!selectedByCalendar && weatherSelectedDateCategory);
  const colors = ["#66A7B4", "#F2BB05", "#FF7373", "#3EB62A"];
  let index = 4;
  let deviceNameLegend =
    deviceTrial !== "" ? deviceTrial : stationId !== "" ? stationId : "CE_HUB Interpolated Data";
  let chartIndex;
  let startIndex;
  let i = 0;
  let countofNulls = 0;
  let lengthoFirstNull;
  let countFlag = 0;
  let lengthFlag = 0;
  let chartData = [];

  envData &&
    envData.forEach((item) => {
      item?.datasets?.forEach((obj) => {
        if (obj?.frequency === "hourly_avg" && obj?.measurements.length > 0) {
          const selecctedDataChart = obj?.measurements.filter((item) =>
            selectedItems[deviceNameLegend]?.includes(item?.variable?.name)
          );

          lengthoFirstNull = 0;
          selecctedDataChart?.map((item) => {
            startIndex = item?.values.findIndex((val) => val !== null);
            item?.values.forEach((obj, idx) => {
              lengthoFirstNull = item?.values?.length;
              lengthFlag = 1;
              if (obj !== null) {
                chartIndex = idx;
              } else {
                countofNulls += 1;
                countFlag = 1;
              }
            });
          });

          selecctedDataChart?.map((item) => {
            if (item?.variable?.name !== "PRECIPITATION_TOTAL")
              chartData.push({
                x: obj?.timeIntervals?.map((item) => parseDates(item)),
                y: item?.values,
                type: "scatter",
                mode: "lines+markers",
                line: { shape: "spline" },
                hovertemplate: `%{x}<br>${item?.variable?.displayName} : %{y}<extra></extra>`,
                name:
                  deviceNameLegend + " _ " + item?.variable?.displayName + " _ " + obj?.frequency,
                marker: {
                  color: colors[index--],
                  pointBorderColor: colors[index--],
                  pointBackgroundColor: colors[index--],
                  size: 1
                }
              });
          });
        }
      });
    });

  envData &&
    envData.forEach((item) => {
      item?.datasets?.forEach((obj) => {
        if (obj?.frequency === "hourly_sum" && obj?.measurements.length > 0) {
          const selecctedDataChart = obj?.measurements.filter(
            (item) =>
              item?.variable?.name === "PRECIPITATION_TOTAL" &&
              selectedItems[deviceNameLegend]?.includes("PRECIPITATION_TOTAL")
          );

          selecctedDataChart?.map((item) => {
            item?.values.forEach((obj, idx) => {
              if (obj === null) {
                countofNulls += 1;
              }
            });
          });

          selecctedDataChart?.map((item) => {
            chartData.push({
              x: obj?.timeIntervals?.map((item) => parseDates(item)),
              y: item?.values,
              type: "scatter",
              mode: "lines+markers",
              line: { shape: "spline" },
              hovertemplate: `%{x}<br>${item?.variable?.displayName} : %{y}<extra></extra>`,
              name: deviceNameLegend + " _ " + item?.variable?.displayName + " _ " + obj?.frequency,
              marker: {
                color: colors[index--],
                pointBorderColor: colors[index--],
                pointBackgroundColor: colors[index--],
                size: 1
              }
            });
          });
        }
      });
    });

  const layout = {
    dragmode: isMobileOnly ? false : true,
    autosize: customMobilePortrait ? false : true,
    height: 500,
    margin: {
      t: isMobileOnly && 40,
      r: customMobilePortrait ? 20 : customMobileLandscape && 40
    },
    legend: { orientation: "v", y: isMobileOnly ? -1.0 : -1.3, x: 0 },
    xaxis: {
      title: "Date",
      type: "date",
      // showline: false,
      showgrid: false,
      range: envData?.length > 0 &&
        envData[0]?.datasets[0]?.timeIntervals?.length > 0 && [
          parseDateRange(envData[0]?.datasets[0]?.timeIntervals[startIndex]),
          parseDateRange(envData[0]?.datasets[0]?.timeIntervals[chartIndex])
        ],
      rangeslider: { visible: true, bgcolor: "#D3D3D3", thickness: 0.1 }
    },
    yaxis: {
      // showgrid: false,
      showline: false,
      title: "value"
    }
  };

  return isLoading ? (
    <div className="env-loader-container">
      <Spinner className="chart-spinner" data-testid="chart-spinner" />
    </div>
  ) : (
    <>
      <div
        style={{
          overflowY: "scroll",
          height:
            customTabletDimensions() && isLandScape
              ? "calc(100vh - 170px)"
              : customMobilePortrait
                ? "calc(100vh - 113px)"
                : customMobileLandscape
                  ? "70vh"
                  : "calc(100vh - 140px)"
        }}>
        <button
          onClick={() => {
            setIsApply(true);
            setSelectedOptionsOnApply(Object.values(selectedItems).flat());
            setSelectedWeatherDevices(Object.values(selectedItems).flat());
            setDeviceVariables([]);
            setSelectedItems({});
            setSelectedWeatherDate(weatherSelectedOption);
          }}
          style={{
            marginTop: customMobileLandscape ? "74px" : "5px",
            marginLeft: "-3px",
            marginRight: customMobilePortrait
              ? "3%"
              : customMobileLandscape
                ? "2%"
                : envExpand
                  ? "8%"
                  : "5%",
            position: customMobilePortrait && "fixed",
            right: customMobilePortrait && 0,
            opacity: emptyObjectCheck(selectedItems) === true ? 0.3 : 1
          }}
          disabled={emptyObjectCheck(selectedItems) === true}
          className="env-apply-button">
          <span style={{ color: "white" }}>APPLY</span>
        </button>

        <Row style={{ marginTop: "42px" }}>
          <Col
            lg={envExpand === false || isTablet || customTabletDimensions() ? 11 : 11}
            md={envExpand === false || isTablet || customTabletDimensions() ? 11 : 11}>
            <div
              className="badge-container-two d-flex"
              style={{
                borderTop: envExpand ? "0.5px solid #E8E8E8" : "none",
                marginTop: envExpand ? "6px" : "0px",
                marginLeft: "32px",
                width: customMobilePortrait
                  ? "600px"
                  : customMobileLandscape
                    ? "80vw"
                    : envExpand
                      ? "90vw"
                      : "46vw"
              }}>
              <div style={{ flex: "0 0 7%" }}>
                <Dropdown
                  show={weatherDropdownOpen}
                  onToggle={handleToggle}
                  className="weather-dropdown-show">
                  <Dropdown.Toggle

                    className="weather-dropdown-toggle"
                    onClick={() => setWeatherDropdownOpen(!weatherDropdownOpen)}
                    style={{
                      cursor: emptyObjectCheck(selectedItems) === true ? "context-menu" : "pointer"
                    }}>
                    <div
                      className="weather-dropdown-data-div"
                      style={{ marginTop: selectDateHidden ? "0px" : "5px" }}>
                      <span className="weather-data-name">
                        <span>
                          {selectDateHidden ? weatherSelectedDateCategory : "Select Date"}
                        </span>
                        <span
                          className="chevron-down-icon"
                          style={{ marginTop: selectDateHidden ? "10px" : "5px" }}>
                          <ChevronDown height={10} width={10} style={{ fill: "#0D1A40" }} />
                        </span>
                      </span>
                      <div className=" weather-option">
                        <span className="weather-selected-text">
                          {selectedByCalendar ||
                          today.toISOString().slice(0, 10) === weatherSelectedOption
                            ? ""
                            : weatherSelectedOption}
                        </span>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  {weatherDropdownOpen && (
                    <Dropdown.Menu className="weather-dropdown-menu">
                      {Object.keys(trialDates).map((key, index) => {
                        return trialDates[key]?.length > 0 ? (
                          <Dropdown.Item key={key} className="custom-weather-dropdown-item">
                            <div
                              key={key}
                              style={{
                                fontSize: "12px",
                                fontWeight: 700,
                                fontFamily: "Helvetica",
                                color: "#0D1A40",
                                padding: index === 0 ? "10px 8px 4px 4px" : "0px 8px 4px 4px"
                              }}>
                              {dateCategories?.[key]}
                            </div>
                            {trialDates[key].sort().map((date, index) => (
                              <div
                                key={index}
                                onClick={() => {
                                  setWeatherSelectedOption(date);
                                  setSelectedWeatherDate(date);
                                  setWeatherSelectedDateCategory(dateCategories?.[key]);
                                  setWeatherSelectedCategory(dateCategories?.[key]);
                                  setSelectedByCalendar(false);
                                }}
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  fontFamily: "Helvetica",
                                  padding: "4px",
                                  color: weatherSelectedOption === date ? "#E08330" : "#0D1A40"
                                }}>
                                {date}
                              </div>
                            ))}
                          </Dropdown.Item>
                        ) : null;
                      })}
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              </div>
              <div
                style={{
                  flex: 1,
                  flexDirection: "row",
                  borderLeft: "0.5px solid #E8E8E8"
                  //padding: "3px 0px 3px 0px"
                }}>
                <Row style={{ marginLeft: "0px" }}>
                  <div className="weatherChart-backward" style={{ marginRight: "2px" }}>
                    <Dropdown onToggle={backwardDaysToggle} show={openBackwardDays}>
                      <Dropdown.Toggle className="weatherChart-backwardDays">
                        <span>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#0D1A40",
                              fontFamily: "Helvetica",
                              marginRight: "14px"
                            }}>
                            - Days {backwardDays}
                          </span>
                          <span style={{ marginRight: "-13px" }}>
                            <ChevronDown height={8} width={8} style={{ fill: "#0D1A40" }} />
                          </span>
                        </span>
                      </Dropdown.Toggle>
                      <div className="custom-dropdown-menu">
                        <Dropdown.Menu col-xs-12 className="dropdown-menu-show">
                          {availableBackwardDays.map((option) => (
                            <Dropdown.Item
                              key={option}
                              eventKey={option}
                              value={option}
                              onClick={() => handleBackwardDays(option)}
                              active={backwardDays === option}>
                              <div
                                className="option-bold"
                                style={{
                                  marginLeft: "18px",
                                  fontSize: "12px",
                                  color: backwardDays == option ? "#E08330" : "#0D1A40",
                                  fontWeight: backwardDays == option ? "bold" : "normal"
                                }}>
                                {option}
                              </div>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </div>
                    </Dropdown>
                  </div>
                  <div style={{ marginRight: "2px" }}>
                    <div
                      onClick={() => setWeatherCalendar(!weatherCalendar)}
                      ref={weatherRef}
                      data-testid="calendar-button"
                      className="weather-calendar-heading">
                      <span>
                        <span className="weather-calendar-icon">
                          <img
                            src={newCalendarIcon}
                            alt="Remove"
                            style={{ height: "18px", width: "18px" }}
                            className="newcrossIcon"
                          />
                        </span>
                        <span className="weather-calendar-selected-option">
                          {weatherSelectedOption ? weatherDate1 : today.toISOString().slice(0, 10)}
                        </span>
                      </span>
                    </div>
                    {weatherCalendar === true && (
                      <div className="weather-calendar-body" ref={weatherDropdownRef}>
                        <Calendar
                          data-testid="calendar"
                          className="calendar"
                          locale="en-GB"
                          onChange={(e) => handleCalender(e.toLocaleDateString())}
                          value={weatherSelectedOption ? new Date(weatherDate1) : new Date()}
                          next2Label={null}
                          prev2Label={null}
                          maxDate={new Date()}
                          formatShortWeekday={formatShortWeekday}
                          calendarType="gregory"
                          tileClassName={tileClassName}
                        />
                      </div>
                    )}
                  </div>

                  <div className="weatherChart-backward">
                    <Dropdown onToggle={forwardDaysToggle} show={openForwardDays}>
                      <Dropdown.Toggle
                        className="weatherChart-backwardDays"
                        disabled={futureDate}
                        style={{
                          opacity: futureDate ? 0.6 : 1,
                          cursor: futureDate ? "context-menu" : "pointer"
                        }}>
                        <span>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#0D1A40",
                              fontFamily: "Helvetica",
                              marginRight: "14px"
                            }}>
                            + Days {forwardDays}
                          </span>
                          <span style={{ marginRight: "-12px" }}>
                            <ChevronDown height={8} width={8} style={{ fill: "#0D1A40" }} />
                          </span>
                        </span>
                      </Dropdown.Toggle>
                      <div className="custom-dropdown-menu">
                        <Dropdown.Menu col-xs-12 className="dropdown-menu-show">
                          {availableForwardDays.map((option) => (
                            <Dropdown.Item
                              key={option}
                              eventKey={option}
                              value={option}
                              role="button"
                              onClick={() => handleForwarddDays(option)}
                              active={forwardDays === option}>
                              <div
                                className="option-bold"
                                style={{
                                  marginLeft: "18px",
                                  fontSize: "12px",
                                  color: forwardDays == option ? "#E08330" : "#0D1A40",
                                  fontWeight: forwardDays == option ? "bold" : "normal"
                                }}>
                                {option}
                              </div>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </div>
                    </Dropdown>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
          <Col
            lg={envExpand === false || isTablet || customTabletDimensions() ? 11 : 11}
            md={envExpand === false || isTablet || customTabletDimensions() ? 11 : 11}>
            <div
              className="badge-container d-flex"
              style={{
                width: customMobilePortrait
                  ? "600px"
                  : customMobileLandscape
                    ? "80vw"
                    : envExpand
                      ? "90vw"
                      : "46vw"
              }}>
              <div style={{ flex: "0 0 7%" }}>
                <Dropdown
                  show={deviceOpen}
                  data-testid="device-dropdown-button"
                  onToggle={deviceToggle}
                  className="weather-dropdown-show">
                  <Dropdown.Toggle
                    className="weather-dropdown-toggle"
                    data-testid="device-trial-option"
                    style={{
                      cursor: "pointer"
                    }}>
                    <div className="select-text-toggle-div">
                      <span className="select-device-text">
                        <span>Select Device and Parameters</span>

                        <span className="chevron-down-icon">
                          <ChevronDown height={10} width={10} style={{ fill: "#0D1A40" }} />
                        </span>
                      </span>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-env">
                    <button className="pr-0 btn des-env-all-button" data-testid="clear-all-button" onClick={handleClearAll}>
                      <span className="env-clear-all-button" >CLEAR ALL</span>
                    </button>
                    <div className="assessment-scroll">
                      {Object.keys(deviceVariables).map((key, index) => {
                        return (
                          <div key={key}>
                            <DropdownButton
                              drop="end"
                              variant="Light"
                              role="menu"
                              className="env-dropdown"
                              title={
                                <span
                                  className="d-flex justify-content-between align-items-center"
                                  style={{ height: "49px" }}>
                                  {selectedItems[key]?.length > 0 && (
                                    <span className="dot-indicator"></span>
                                  )}
                                  <span className="env-option" style={{ width: "96px" }}>
                                    {key != ""
                                      ? key
                                      : stationId !== ""
                                        ? stationId
                                        : "CE_HUB Interpolated Data"}
                                  </span>
                                  <span style={{ marginRight: "50px" }}>
                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setOpenModal(true);
                                      }}>
                                      <InfoCircle height={18} width={18} color="black" data-testid="info-circle-icon" aria-label="info" role="button" />
                                    </div>
                                  </span>
                                  {selectedItems[key]?.length > 0 && (
                                    <span className="selected-count">
                                      ({selectedItems[key].length})
                                    </span>
                                  )}
                                  <ChevronRight height={8} width={8} style={{ fill: "#0D1A40" }} />
                                </span>
                              }>
                              <div>
                                <div className="p-2" style={{ background: "white" }}>
                                  <button
                                    data-testid="deselect-all-button"

                                    className="pr-0 btn des-env-all-button"
                                    onClick={() => handleDeselectAll(key)}>
                                    <span className="env-clear-all-button">DESELECT ALL</span>
                                  </button>
                                  <div
                                    className="scrollbar-assessment"
                                    style={{
                                      overflowY: "auto",
                                      paddingRight: "8px"
                                    }}>
                                    {deviceVariables[key]?.length > 0 &&
                                      deviceVariables[key].map((elem, index) => (
                                        <div
                                          className="d-flex justify-content-between align-items-center"
                                          style={{
                                            opacity:
                                              !selectedItems[key]?.includes(`${key}-${elem}`) &&
                                                totalSelected >= 4
                                                ? 0.5
                                                : 1
                                          }}
                                          key={index}>
                                          <div
                                            className="d-flex py-1 align-items-center"
                                            key={index}
                                            style={{ whiteSpace: "noWrap" }}>
                                            <label className="checkbox-label">
                                              <input
                                                aria-label={elem}
                                                data-testid="temperature-checkbox"
                                                type="checkbox"
                                                name="options"
                                                className="assessment-input"
                                                style={{ width: "16px", height: "16px" }}
                                                checked={
                                                  selectedItems[key]?.includes(`${elem}`) || false
                                                }
                                                onChange={() => handleCheckboxChange(key, elem)}
                                                disabled={
                                                  !selectedItems[key]?.includes(`${elem}`) &&
                                                  totalSelected >= 4
                                                }
                                              />
                                              <span
                                                style={{
                                                  paddingLeft: "8px",
                                                  fontSize: "12px",
                                                  fontWeight: "400",
                                                  color: "#0D1A40"
                                                }}>
                                                {elem}
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </DropdownButton>
                          </div>
                        );
                      })}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div style={{ flex: 1, borderLeft: "0.5px solid #E8E8E8" }}>
                <div
                  style={{
                    height: emptyObjectCheck(selectedItems) === true && "40px"
                  }}>
                  <span className="chip-span">
                    {Object.keys(selectedItems).map((key) =>
                      selectedItems[key].map((item, index) => {
                        const [itemKey, itemDate] = item.split("-");
                        return (
                          <div key={index} className="badge-item">
                            <Button
                              variant="link"
                              style={{ color: "white", textDecoration: "none", padding: "0 0px" }}
                              onClick={() => handleRemoveChip(key, item)}>
                              <img
                                src={newCross}
                                alt="Remove"
                                style={{ marginLeft: "-9px", marginBottom: "13px" }}
                                className="newcrossIcon"
                              />
                            </Button>
                            <span className="badge-text2">{itemDate}</span>
                            <span className="badge-text1">{itemKey}</span>
                          </div>
                        );
                      })
                    )}
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div style={{ width: "100%" }}>
          {/* {error ||
            selectedItems[deviceNameLegend]?.length === 0 ||
            (countFlag === 1 &&
              lengthFlag === 1 &&
              countofNulls === selectedItems[deviceNameLegend]?.length * lengthoFirstNull) ? (
            <div className="d-flex ml-3 mt-3 align-items-center justify-content-center p-3 ">
              <EmojiFrown color="#FF3F3F" />
              <span className="no-data-text ml-2">No Data on selected date</span>
            </div>
          ) :  */}
          {(
            emptyObjectCheck(selectedItems) !== true &&
            (chartData?.length !== 0 || chartData !== undefined) && (
              <Plot
                data={chartData.filter((item) => item !== undefined)}
                style={{ cursor: "pointer", width: "100%" }}
                layout={layout}
                config={{
                  staticPlot: false,
                  displaylogo: false,
                  responsive: customMobilePortrait ? false : true,
                  animation: false,
                  modeBarButtonsToRemove: [
                    "pan2d",
                    "select2d",
                    "autoScale2d",
                    "hoverClosestCartesian",
                    "zoom2d",
                    "lasso2d"
                  ]
                }}></Plot>
            )
          )}
          {/* } */}
        </div>
      </div>
      <div className="modalContent">
        <Modal
          show={openModal}
          backdrop="true"
          centered
          // className={
          //   isMobile
          //     ? "mobileTrialSummary"
          //     : customMobileLandscape
          //     ? "landScapeTrialSummary"
          //     : !(customMobilePortrait || customMobileLandscape || customTabletDimensions())
          //     ? "desktopTrialSummary"
          //     : "tabTrialSummary"
          // }
          onHide={() => {
            setOpenModal(false);
          }}>
          <Modal.Body className="py-0">
            <div className="mt-3 mb-4">
              <span
                style={{
                  fontSize: "24px",
                  fontFamily: "Helvetica",
                  fontWeight: 700,
                  color: "#0D1A40"
                }}>
                {deviceNameLegend}
              </span>
            </div>
            <div className="env-close-button" data-testid="modal-close-button" onClick={() => setOpenModal(false)}>
              <X width={24} height={24} />
            </div>
            <Row style={{ marginBottom: !customMobilePortrait ? "10px" : "" }}>
              {customMobilePortrait ? (
                <>
                  <Col className="col-6 mb-2">
                    <div>
                      <div
                        style={{ marginLeft: "1px" }}
                        className="font-size-small font-weight-700 font-family-Helvetica deep-cove-color mb-2">
                        StationType
                      </div>
                      <div className="font-size-small font-weight-400 font-family-Helvetica deep-cove-color mb-2">
                        {stationType !== "" ? stationType : totalData?.source}
                      </div>
                    </div>
                    {/* <div style={{ margin: "10px 0px" }}>
                      <div className="font-size-small font-weight-700 font-family-Helvetica deep-cove-color">
                        Distance
                      </div>
                      <div className="font-size-small font-weight-400 font-family-Helvetica deep-cove-color">
                        10km
                      </div>
                    </div> */}
                  </Col>
                  <Col className="col-6 mb-2">
                    <div>
                      <div className="font-size-small font-weight-700 font-family-Helvetica deep-cove-color mb-2">
                        Coordinates (lat, lon)
                      </div>
                      <div className="font-size-small font-weight-400 font-family-Helvetica deep-cove-color mb-2">
                        {stationGeometry[1]}, {stationGeometry[0]}
                      </div>
                    </div>
                    {/* <div style={{ margin: "10px 0px" }}>
                      <div className="font-size-small font-weight-700 font-family-Helvetica deep-cove-color">
                        Direction
                      </div>
                      <div className="font-size-small font-weight-400 font-family-Helvetica deep-cove-color">
                        West
                      </div>
                    </div> */}
                  </Col>
                </>
              ) : (
                <>
                  <Col className="col-6">
                    <div>
                      <div className="font-size-small font-weight-700 font-family-Helvetica deep-cove-color mb-2">
                        StationType
                      </div>
                      <div
                        style={{ marginLeft: "1px" }}
                        className="font-size-small font-weight-400 font-family-Helvetica deep-cove-color mb-2">
                        {stationType !== "" ? stationType : totalData?.source}
                      </div>
                    </div>
                  </Col>
                  <Col className="col-6">
                    <div>
                      <div className="font-size-small font-weight-700 font-family-Helvetica deep-cove-color mb-2">
                        Coordinates (lat, lon)
                      </div>
                      <div className="font-size-small font-weight-400 font-family-Helvetica deep-cove-color mb-2">
                        {stationGeometry[1]}, {stationGeometry[0]}
                      </div>
                    </div>
                  </Col>
                  {/* <Col className="col-2">
                    <div>
                      <div className="font-size-small font-weight-700 font-family-Helvetica deep-cove-color">
                        Distance
                      </div>
                      <div className="font-size-small font-weight-400 font-family-Helvetica deep-cove-color">
                        10km
                      </div>
                    </div>
                  </Col>
                  <Col className="col-2">
                    <div>
                      <div className="font-size-small font-weight-700 font-family-Helvetica deep-cove-color">
                        Direction
                      </div>
                      <div className="font-size-small font-weight-400 font-family-Helvetica deep-cove-color">
                        West
                      </div>
                    </div>
                  </Col> */}
                </>
              )}
            </Row>

            <div></div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
WeatherChart.propTypes = {
  envExpand: propTypes.bool,
  selectedTrial: propTypes.any,
  deviceId: propTypes.any,
  deviceTrial: propTypes.any,
  geometry: propTypes.any,
  customMobileLandscape: propTypes.any,
  customMobilePortrait: propTypes.any,
  isMobileOnly: propTypes.any,
  planDataLoaded: propTypes.any,
  envTrack: propTypes.any,
  selectedWeatherDate: propTypes.any,
  setSelectedWeatherDate: propTypes.any,
  selectedWeatherDevices: propTypes.any,
  setSelectedWeatherDevices: propTypes.any,
  selectedWeatherDateUrl: propTypes.any,
  selectedWeatherDevicesUrl: propTypes.any,
  weatherEndDate: propTypes.any,
  setWeatherEndDate: propTypes.any,
  weatherStartDate: propTypes.any,
  setWeatherStartDate: propTypes.any,
  weatherForwardDays: propTypes.any,
  setWeatherForwardDays: propTypes.any,
  setWeatherBackwardDays: propTypes.any,
  weatherBackwardDays: propTypes.any,
  weatherSelectedCategory: propTypes.any,
  setWeatherSelectedCategory: propTypes.any,
};
export default WeatherChart;
